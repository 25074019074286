import { Box, Flex, Button, Text, Image, Link, useColorMode, Icon, useToast } from '@chakra-ui/react'
import logoProcademy from '../../../../assets/img/LogoProcademyBlack.png'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { iconoTenant } from '../../../../shared/middlewares/tenant.middleware';
import { useTenantInfo } from '../../../../shared/hooks/useTenantInfo';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { registerEmail } from '../../../../shared/middlewares/register.middleware';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/types/StatusEnumTypes';

export const VerifyEmail = () => {
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const navigate = useNavigate()
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });
    const toast = useToast()

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingWidth, setLoadingWidth] = useState("0%")
    const [loadingSendCode, setLoadingSendCode] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!loading) return;

        let width = 0;
        const interval = setInterval(() => {
            width += 1;
            setLoadingWidth(`${width}%`);

            if (width >= 90) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [loading]);

    useEffect(() => {
        if (loading) return;

        setLoadingWidth("100%");

        setTimeout(() => {
            setLoadingWidth("0%");
        }, 500);
    }, [loading]);

    const initialValues = {
        userId: null,
        password: null
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("El campo debe ser un email válido")
            .required("El campo es obligatorio")
            .typeError('El campo es obligatorio'),
    });

    const submitForm = async (values: any) => {
        setLoadingSendCode(true)

        return registerEmail({ email: values.email })
            .then(() => {
                localStorage.setItem("verifyEmail", values.email)

                navigate("/register/validateCode")
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Se ha enviado un código a tu email")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoadingSendCode(false))
    };

    return (
        <Flex
            w={"100%"}
            bg="black"
            align="center"
            justify="center"
        >
            <Box
                pos={"fixed"}
                top="0"
                bg="#8694FF"
                h="3px"
                w={loadingWidth}
            />

            <Flex
                direction={"column"}
                align="start"
                justify="center"
                w="320px"
            >
                {loading ?
                    <Flex
                        h="65px"
                        rounded="6px"
                    />
                    :
                    (
                        (icono && nombre) ?
                            <Flex
                                align="center"
                                justify='center'
                                gap="5px"
                            >
                                <Image
                                    src={icono}
                                    alt='img/logo'
                                    objectFit="contain"
                                    w="25px"
                                    h="25px"
                                />

                                <Text
                                    textTransform="capitalize"
                                    fontFamily="Poppins"
                                    fontSize="15px"
                                    color="purewhite"
                                    fontWeight="800"
                                >
                                    {nombre}
                                </Text>
                            </Flex>
                            :
                            <Image
                                src={logoProcademy}
                                alt='img/logo'
                                objectFit="contain"
                                w="200px"
                                p="0 3%"
                            />
                    )
                }

                <Flex
                    direction="column"
                    align='start'
                    justify='center'
                    textAlign='center'
                    gap="4px"
                    mt="30px"
                    mb="45px"
                >
                    <Text
                        fontFamily="Poppins"
                        fontWeight="400"
                        fontSize="22px"
                        color="purewhite"
                    >
                        Bienvenido a {nombre}
                    </Text>

                    <Flex
                        alignItems="center"
                    >
                        <Text
                            fontWeight="400"
                            lineHeight="normal"
                            fontSize="14px"
                            color="rgba(255, 255, 255, 0.50)"
                        >
                            ¿Ya tienes una cuenta?
                        </Text>

                        <Link
                            as={RouterLink}
                            to="/login"
                            color={colores?.main ? colores?.main : 'main'}
                            fontWeight="400"
                            fontSize={"14px"}
                            cursor="pointer"
                            mx="10px"
                        >
                            Inicia sesión
                        </Link>

                        <Icon
                            mt="2px"
                            as={FaArrowRight}
                            boxSize="13px"
                            color={colores?.main ? colores?.main : 'main'}
                        />
                    </Flex>
                </Flex>

                {!loading &&
                    <Formik
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {(formik) => {
                            const { handleSubmit } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                    style={{ minWidth: "420px" }}
                                >
                                    <Flex
                                        direction="column"
                                        alignItems="center"
                                        gap="5px"
                                    >
                                        <FormInput
                                            name='email'
                                            label='Introduce tu email'
                                            placeholder="Email"
                                            ui={{
                                                styleInput: {
                                                    border: "1px solid rgba(255, 255, 255, 0.10)",
                                                    rounded: "10px",
                                                    bg: "transparent",
                                                    color: "purewhite",
                                                },
                                                styleLabel: {
                                                    color: "purewhite"
                                                },
                                            }}
                                        />

                                        <Button
                                            type='submit'
                                            background="rgba(255, 255, 255, 0.05)"
                                            color="rgba(255, 255, 255, 0.20)"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="20px"
                                            rounded="10px"
                                            w="100%"
                                            h="fit-content"
                                            py="11px"
                                            px="25px"
                                            mt="16px"
                                            isLoading={loadingSendCode}
                                            _hover={{}}
                                        >
                                            Continuar
                                        </Button>

                                        <Flex
                                            direction="column"
                                            gap="30px"
                                            mt="30px"
                                        >
                                            <Text
                                                color="rgba(255, 255, 255, 0.50)"
                                                fontSize="13px"
                                                fontWeight="400"
                                                lineHeight="20px"
                                            >
                                                Registrándote, aceptas los {" "}
                                                <Text
                                                    as="span"
                                                    textDecoration="underline"
                                                >
                                                    Términos de Servicio
                                                </Text>
                                                {" "} y la {" "}
                                                <Text
                                                    as="span"
                                                    textDecoration="underline"
                                                >
                                                    Política de Privacidad.
                                                </Text>
                                            </Text>

                                            <Text
                                                color="rgba(255, 255, 255, 0.50)"
                                                fontSize="13px"
                                                fontWeight="400"
                                                lineHeight="20px"
                                            >
                                                ¿Necesitas ayuda? {" "}
                                                <Text
                                                    as="span"
                                                    color={colores?.main ? colores?.main : 'main'}
                                                >
                                                    Contacte con soporte
                                                </Text>
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                }
            </Flex>
        </Flex>
    )
}