import { EntregablesInt, UpdateEntregablesInt } from "../../interfaces/Entregables";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EntregableEstadoEnum } from "../utils/types/EntregableEstadoEnum";

export const addEntregable = async ({ data }: { data: Partial<EntregablesInt> }) => {
    const { tenant, client } = await tenantInstance();
    const form = new FormData()
    const today = new Date().toISOString()

    form.append('adjunto', data?.adjunto)
    form.append('estado', EntregableEstadoEnum.PENDIENTE_CORRECCION);
    form.append('fechaEntrega', today)
    data?.matriculaId && form.append('matriculaId', data?.matriculaId)
    data?.leccionId && form.append('leccionId', data?.leccionId)
    data?.moduloId && form.append('moduloId', data?.moduloId)
    data?.cursoId && form.append('cursoId', data?.cursoId)

    return await _axios.post(
        `${tenant}/${client}/entregables`,
        form,
        {}
    ).then((response: any) => response);
};

export const updateEntregables = async ({ id, data }: { id: string, data: UpdateEntregablesInt }) => {
    const { tenant, client } = await tenantInstance();

    return await _axios.put(
        `${tenant}/${client}/entregables/${id}`,
        data,
        {}
    ).then((response: any) => response);
};

export const corrigeEjercicio = async ({
    slug,
    data
}: {
    slug: string,
    data: {
        matriculaId: string,
        respuesta: string | Blob
    }
}) => {
    const { tenant, client } = await tenantInstance();
    const form = new FormData()

    form.append('matriculaId', data.matriculaId)

    if (data.respuesta instanceof Blob) {
        const audioFile = new File([data.respuesta], 'audio.wav', {
            type: data.respuesta.type
        });
        form.append('respuesta', audioFile);
    } else {
        form.append('respuesta', data.respuesta);
    }

    return await _axios.post(
        `${tenant}/${client}/lecciones/${slug}/corrigeEjercicio`,
        form
    ).then((response: any) => response);
}