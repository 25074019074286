import { Flex, Skeleton, SkeletonCircle, useColorMode } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useParams } from "react-router-dom"
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import RutaDescription from "../../components/RutaDescription";
import { CursosInt } from "../../../../interfaces/CursosInt";
import RutaCurso from "../../components/RutaCurso";
import { useData } from "../../../../shared/hooks/useData";
import { useAuthContext } from "../../../../shared/context/user.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";

export const InformationRuta = () => {
    const { colorMode } = useColorMode()
    const { slug } = useParams();
    const { user } = useAuthContext();

    const { data: ruta, loading } = useDataId({
        endpoint: EndpointTypes.RUTAS,
        id: slug
    })

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });


    const matriculasEnRutas = matriculas?.data?.filter((matricula: any) => {
        return ruta?.cursos?.some((curso: any) => curso?.id === matricula?.grupo?.curso?.id);
    });


    const totalTiempoAcceso = matriculasEnRutas?.reduce((total: number, matricula: any) => {
        return total + matricula?.tiempoAcceso;
    }, 0);

    const progresoTotal = matriculasEnRutas?.reduce((total: number, matricula: any) => {
        return total + matricula?.progresoLecciones;
    }, 0);

    const porcentajeTotal = Math.floor((progresoTotal / (matriculasEnRutas?.length * 100)) * 100);

    const matriculasProgreso100 = matriculasEnRutas?.filter((matricula: any) => {
        return matricula?.progresoLecciones === 100;
    });

    const matriculasAcabadas = matriculasProgreso100?.length;

    return (
        <Topbar
            title={"Hoja de ruta - " + ruta?.nombre}
            extraTitle={ruta?.cursos?.length + " cursos"}
            searchBar
        >
            <Flex
                direction="column"
                w="100%"
                p="40px"
            >
                {loading ?
                    Array.from({ length: 2 }, (_, i) => i).map((_, index: number, array: number[]) => (
                        <Flex
                            gap="20px"
                            w="100%"
                        >
                            <Flex
                                direction="column"
                                alignItems="center"
                                mt={index === 0 ? "47px" : "0"}
                                w="32px"
                            >
                                <Flex
                                    display={index === 0 ? "none" : "flex"}
                                    border="1px solid"
                                    h="50px"
                                    borderColor={colorMode === "dark" ? "border_variant" : "gray.200"}
                                />

                                <SkeletonCircle
                                    flex={"1"}
                                    maxH={"32px"}
                                    h="32px"
                                    w="32px"
                                    my="5px"
                                />

                                <Flex
                                    flex={"1"}
                                    borderWidth="1px"
                                    borderStyle={index === array.length - 1 ? "dashed" : "solid"}
                                    h="full"
                                    borderColor={colorMode === "dark" ? "border_variant" : "gray.200"}
                                />
                            </Flex>

                            <Skeleton
                                mt={index !== 0 ? "20px" : "0"}
                                w="100%"
                                h="202px"
                                rounded="20px"
                            />
                        </Flex>
                    ))
                    :
                    <Flex
                        direction="column"
                        gap="20px"
                    >
                    {ruta?.cursos?.map((curso: CursosInt, index: number, array: CursosInt[]) => (
                        <RutaCurso
                            key={index}
                            curso={curso}
                            topLine={index === 0}
                            dashedLine={index === array.length - 1}
                        />
                        ))}
                    </Flex>
                }
            </Flex>
        </Topbar>
    )
}
