import axios from "axios";
import tenantInstance from "../services/tenantInstance.service";

export const registerEmail = async ({ email }: { email: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/registerEmail`,
        { email }
    );
};

export const validateCode = async ({ codigo, email }: { codigo: string, email: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/verifyEmail`,
        {
            codigo,
            email
        }
    );
};

export const checkRegisterHash = async ({ hash }: { hash: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/checkRegisterHash`,
        { hash }
    );
};

export const registerComplete = async ({ 
    hash,
    nombre,
    apellidos,
    password,
}: { 
    hash: string,
    nombre: string,
    apellidos: string,
    password: string,
}) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/register`,
        { 
            hash,
            nombre,
            apellidos,
            password
        }
    );
};