import { ChatInt, MensajesInt, UpdateChatInt, UpdateMessageInt } from "../../interfaces/MessagesInt";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EstadoMensaje } from "../utils/types/MensajeriaEnum";

const { tenant, client } = await tenantInstance();

export const createChat = async (newChat: ChatInt) => {

    return await _axios.post(
        `${tenant}/${client}/mensajeria/conversaciones`,
        newChat,
        {}
    );
};

export const updateChat = async ({ idChat, updateChat }: { idChat: string, updateChat: UpdateChatInt }) => {

    return await _axios.put(
        `${tenant}/${client}/mensajeria/conversaciones/${idChat}`,
        updateChat,
        {}
    ).then((response: any) => response);
};

export const createMessage = async (newMessage: any) => {

    return await _axios.post(
        `${tenant}/${client}/mensajeria/mensajes`,
        newMessage,
        {}
    ).then((response: any) => response);
};

export const readMessage = async (id: string) => {

    return await _axios.put(
        `${tenant}/${client}/mensajeria/mensajes/${id}`,
        {
            estado: EstadoMensaje.LEIDO
        },
        {}
    ).then((response: any) => response);
};

export const updateMessage = async ({ id, estado }: { id: string, estado: EstadoMensaje }) => {
    return await _axios.put(
        `${tenant}/${client}/mensajeria/mensajes/${id}`,
        { estado },
        {}
    ).then((response: any) => response);
};