import { Box, Flex, Icon, Text, useColorMode } from "@chakra-ui/react"
import { HiOutlineClipboard } from "react-icons/hi"
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt"
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";
import { TipoEntregaEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { PiSparkleBold } from "react-icons/pi";
import { ResolverAutocorregible } from "./components/ResolverAutocorregible";
import { AutocorregibleNavigation } from "../../../Aprendizaje/components/Information/AutocorregibleNavigation";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { PreguntaInt } from "../../../../interfaces/PreguntasInt";
import { useNavigate, useParams } from "react-router-dom";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";

interface Props {
    leccion: LeccionInt | undefined;
    realizarCorreccion: (respuesta: any) => Promise<void>;
    componentSubtype: ComponenteLeccionEnum | undefined;
    loading: boolean;
    matriculaActual: MatriculasInt | undefined;
    questions: PreguntaInt[];
    leccionSelected: LeccionInt | undefined;
    setLeccionSelected: Dispatch<SetStateAction<LeccionInt | undefined>>;
    modulos: ModulosInt[];
}

export const AutocorregibleEnunciado = ({
    leccion,
    realizarCorreccion,
    componentSubtype,
    loading,
    questions,
    leccionSelected,
    setLeccionSelected,
    modulos,
    matriculaActual
}: Props) => {
    const { colorMode } = useColorMode();
    const navigate = useNavigate();
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [currentAnswer, setCurrentAnswer] = useState<string | Blob>("");


    useEffect(() => {
        if (leccion && !leccionSelected) {
            setLeccionSelected(leccion);
        }
    }, [leccion, leccionSelected]);

    // const getAllAILessons = () => {
    //     return modulos.reduce((allLessons: LeccionInt[], modulo) => {
    //         const moduleLessons = modulo.lecciones.filter(leccion => leccion.AI !== null);
    //         return [...allLessons, ...moduleLessons];
    //     }, []);
    // };

    const navigateToLesson = (newLeccion: LeccionInt) => {
        setLeccionSelected(newLeccion);
        navigate(`/aprendizaje/${leccion?.modulo?.curso?.slug}/lecciones/${newLeccion.slug}?grupo=${matriculaActual?.grupo?.slug}`);
    };
    
    const handleNextQuestion = () => {
        const currentModuleIndex = modulos.findIndex(modulo => 
            modulo.lecciones.some(leccion => leccion.id === leccionSelected?.id)
        );
    
        if (currentModuleIndex !== -1) {
            const currentModule = modulos[currentModuleIndex];
            const currentLeccionIndex = currentModule.lecciones.findIndex(
                leccion => leccion.id === leccionSelected?.id
            );

            const nextLeccionWithAI = currentModule.lecciones
                .slice(currentLeccionIndex + 1)
                .find(leccion => leccion.AI !== null);

            if (nextLeccionWithAI) {
                navigateToLesson(nextLeccionWithAI);
                return;
            }

            for (let i = currentModuleIndex + 1; i < modulos.length; i++) {
                const nextLeccionWithAI = modulos[i].lecciones
                    .find(leccion => leccion.AI !== null);
                
                if (nextLeccionWithAI) {
                    navigateToLesson(nextLeccionWithAI);
                    return;
                }
            }
        }
    };

    const handlePrevQuestion = () => {
        const currentModuleIndex = modulos.findIndex(modulo => 
            modulo.lecciones.some(leccion => leccion.id === leccionSelected?.id)
        );
        
        if (currentModuleIndex !== -1) {
            const currentModule = modulos[currentModuleIndex];
            const currentLeccionIndex = currentModule.lecciones.findIndex(
                leccion => leccion.id === leccionSelected?.id
            );
            
            const prevLeccionWithAI = currentModule.lecciones
                .slice(0, currentLeccionIndex)
                .reverse()
                .find(leccion => leccion.AI !== null);

            if (prevLeccionWithAI) {
                navigateToLesson(prevLeccionWithAI);
                return;
            }

            for (let i = currentModuleIndex - 1; i >= 0; i--) {
                const prevLeccionWithAI = modulos[i].lecciones
                    .reverse()
                    .find(leccion => leccion.AI !== null);
                
                if (prevLeccionWithAI) {
                    navigateToLesson(prevLeccionWithAI);
                    return;
                }
            }
        }
    };

    const hasNextAILesson = () => {
        const currentModuleIndex = modulos.findIndex(modulo => 
            modulo.lecciones.some(leccion => leccion.id === leccionSelected?.id)
        );

        if (currentModuleIndex !== -1) {
            const currentModule = modulos[currentModuleIndex];
            const currentLeccionIndex = currentModule.lecciones.findIndex(
                leccion => leccion.id === leccionSelected?.id
            );

            const hasNextInCurrentModule = currentModule.lecciones
                .slice(currentLeccionIndex + 1)
                .some(leccion => leccion.AI !== null);

            if (hasNextInCurrentModule) return true;

            for (let i = currentModuleIndex + 1; i < modulos.length; i++) {
                if (modulos[i].lecciones.some(leccion => leccion.AI !== null)) {
                    return true;
                }
            }
        }
        return false;
    };

    const hasPrevAILesson = () => {
        const currentModuleIndex = modulos.findIndex(modulo => 
            modulo.lecciones.some(leccion => leccion.id === leccionSelected?.id)
        );

        if (currentModuleIndex !== -1) {
            const currentModule = modulos[currentModuleIndex];
            const currentLeccionIndex = currentModule.lecciones.findIndex(
                leccion => leccion.id === leccionSelected?.id
            );

            const hasPrevInCurrentModule = currentModule.lecciones
                .slice(0, currentLeccionIndex)
                .some(leccion => leccion.AI !== null);

            if (hasPrevInCurrentModule) return true;

            for (let i = currentModuleIndex - 1; i >= 0; i--) {
                if (modulos[i].lecciones.some(leccion => leccion.AI !== null)) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Flex
            p="40px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="25px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                mb="6px"
                gap="10px"
            >
                <Flex
                    direction="column"
                    gap="10px"
                    w="100%"
                    justifyContent="space-between"
                >
                    <Text
                        color="font"
                        fontSize="21px"
                        fontStyle="normal"
                        fontWeight="700"
                    >
                        {leccion?.nombre}
                    </Text>

                    <Flex
                        gap="5px"
                        alignItems="center"
                    >
                        <Icon
                            as={PiSparkleBold}
                            color="white"
                            boxSize="18px"
                        />

                        <Text
                            color="white"
                            fontSize="15px"
                            fontWeight="400"
                        >
                            Autocorregible
                        </Text>
                    </Flex>
                </Flex>

                <AutocorregibleNavigation
                    currentQuestion={currentQuestion}
                    totalQuestions={questions.length}
                    onNextQuestion={handleNextQuestion}
                    onPrevQuestion={handlePrevQuestion}
                    hasNext={hasNextAILesson()}
                    hasPrev={hasPrevAILesson()}
                    fullWidth
                />

            </Flex>

            <Box h="1px" bg="#E6E6EA" w="100%" />

            {leccion?.contenido &&
                <>
                    <Flex
                        w="100%"
                    >
                        {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                            <PDFViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                            <Video
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                            <IframeViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                            <ImageViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                            <MarkdownViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }
                    </Flex>

                    <Box h="1px" bg="#E6E6EA" w="100%" />
                </>
            }

            <ResolverAutocorregible
                tipo={TipoEntregaEnum.ENUNCIADO}
                leccion={leccion}
                loading={loading}
                realizarCorreccion={realizarCorreccion}
                currentAnswer={currentAnswer}
                setCurrentAnswer={setCurrentAnswer}
            />
        </Flex>
    )
}