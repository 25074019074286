import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Icon, Text, Tooltip, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { BiCheck, BiLockAlt, BiPlay } from "react-icons/bi";
import { sortByOrder } from "../../../../shared/utils/functions/sortBy";
import { CursosInt, LeccionInt } from "../../../../interfaces/CursosInt";
import { useAuthContext } from "../../../../shared/context/user.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { checkLessonComplete, checkLessonStart, validateProgressModule } from "../../../../shared/utils/functions/validateProgress";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProgressContext } from "../../../../shared/context/progress.context";
import { truncate } from "lodash";
import { HiOutlineSparkles } from "react-icons/hi2";


interface Props {
    curso: CursosInt;
    contenidoOpen: boolean
    setInTop: (value: boolean) => void
    setContenidoOpen?: (value: boolean) => void
    onClose?: () => void
}


const AccordionContenido = ({ curso, contenidoOpen, setInTop, onClose, setContenidoOpen }: Props) => {
    const { colorMode } = useColorMode();
    const params = useParams()
    const navigate = useNavigate()
    const { user } = useAuthContext()
    const { cursoProgress } = useProgressContext()
    const [search] = useSearchParams()
    const regexNombre = /Tema \d+.*-\s*/;
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    const handleScroll = (e: any) => {
        const element = e.target.scrollTop;

        if (element > 0) setInTop(true);
        else setInTop(false);
    }

    return (
        <Accordion
            h={"calc(100vh - 74px)"}
            overflow="auto"
            css={{
                '&::-webkit-scrollbar': {
                    width: '0px',
                },
                scrollBehavior: 'smooth',
            }}
            onScroll={handleScroll}
            allowToggle
            w="100%"
            transition="all 0.2s"
            opacity={contenidoOpen ? 1 : 0}
        >
            {sortByOrder(curso?.modulos || []).map((modulo, index) => {
                const leccionesIA = sortByOrder(modulo?.lecciones)?.filter(
                    (leccion: LeccionInt) => leccion?.AI == "texto" || leccion?.AI == "audio" || leccion?.AI == "codigo"
                );
                const leccionesNoIA = sortByOrder(modulo?.lecciones)?.filter(
                    (leccion: LeccionInt) => leccion?.AI !== "texto" && leccion?.AI !== "audio" && leccion?.AI !== "codigo"
                );

                return (
                    <AccordionItem
                        key={index}
                        borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                        isDisabled={!open}
                        w="100%"
                    >
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton
                                    _disabled={{ opacity: "1", cursor: "not-allowed" }}
                                    display="flex"
                                    p={{ base: "20px 10px", md: "20px 30px" }}
                                    justifyContent="space-between"
                                    borderBottom={isExpanded ? "1px solid" : "none"}
                                    borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                    _focus={{ boxShadow: "none" }}
                                >
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                        justifyContent="space-between"
                                        w="100%"
                                    >
                                        <Flex
                                            direction="column"
                                            alignItems="start"
                                        >
                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="700"
                                                align="start"
                                            >
                                                {sortByOrder(curso?.modulos)[0]?.nombre?.includes("Recurso") || sortByOrder(curso?.modulos)[0]?.nombre?.includes("recurso") ? index : index + 1}. {truncate(modulo?.nombre?.replace(regexNombre, ""), { length: 50 })}
                                            </Text>

                                            <Text
                                                color="#8C909C"
                                                fontSize={"13px"}
                                                fontWeight="400"
                                            >
                                                {modulo?.lecciones?.length} lecciones
                                            </Text>
                                        </Flex>

                                        {user?.role?.nombre === UserRolEnum.ALUMNO && (
                                            validateProgressModule(cursoProgress, modulo) > 99 ?
                                                <Icon
                                                    color={colorMode === "dark" ? "purewhite" : "main"}
                                                    boxSize="24px"
                                                    as={BiCheck}
                                                />
                                                :
                                                <Icon
                                                    as={BiLockAlt}
                                                    color="#A5A8B3"
                                                    boxSize="22px"
                                                />
                                        )}
                                    </Flex>

                                    <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel p="0">
                                    {leccionesNoIA?.map((leccion: LeccionInt, index: number) => (
                                        <Flex
                                            w="100%"
                                            key={index}
                                            mb="5px"
                                            cursor="pointer"
                                            _hover={{ bg: colorMode === "dark" ? "main" : "main_variant" }}
                                            bgColor={leccion?.slug === params["*"] ? colorMode === "dark" ? "main" : "main_variant" : "inherit"}
                                            onClick={() => {
                                                navigate(`${leccion?.slug}${addRedirect}`);
                                                onClose && onClose();
                                                setContenidoOpen && setContenidoOpen(!contenidoOpen);
                                            }}
                                        >
                                            <Box
                                                opacity={leccion?.slug === params["*"] ? 1 : 0}
                                                bg={colorMode === "dark" ? "purewhite" : "main"}
                                                w="4px"
                                            />

                                            <Flex
                                                alignItems="center"
                                                justifyContent="space-between"
                                                w="100%"
                                                pr="20px"
                                            >
                                                <Text
                                                    py="12px"
                                                    px="20px"
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="400"
                                                >
                                                    {leccion?.nombre}
                                                </Text>

                                                {user?.role?.nombre === UserRolEnum.ALUMNO &&
                                                    <Icon
                                                        display={(checkLessonComplete(cursoProgress?.data, leccion?.id) || checkLessonStart(cursoProgress?.data, leccion?.id)) ? "block" : "none"}
                                                        as={checkLessonComplete(cursoProgress?.data, leccion?.id) ? BiCheck : BiPlay}
                                                        boxSize="20px"
                                                        color={colorMode === "dark" ? "purewhite" : "main"}
                                                    />
                                                }
                                            </Flex>
                                        </Flex>
                                    ))}

                                    {leccionesIA?.length > 0 && (
                                        <Flex
                                            direction="column"
                                            px="10px"
                                            py="10px"
                                            mx="10px"
                                            my="10px"
                                            bg={colorMode === "dark" ? "#1E2329" : "white"}
                                            rounded="14px"
                                            border="1px solid"
                                            borderColor={colorMode === "dark" ? "main" : "border"}
                                        >
                                            <Accordion allowToggle width="100%">
                                                <AccordionItem border="none">
                                                    {({ isExpanded }) => (
                                                        <>
                                                            <AccordionButton
                                                                p="0"
                                                                _hover={{ bg: 'transparent' }}
                                                                _focus={{ boxShadow: 'none' }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    gap="8px"
                                                                    justifyContent="space-between"
                                                                    width="100%"
                                                                >
                                                                    <Flex align="center" gap="8px">
                                                                        <Text
                                                                            fontSize="16px"
                                                                            fontWeight="500"
                                                                            color="font"
                                                                        >
                                                                            Autocorregibles
                                                                        </Text>

                                                                        <Icon
                                                                            as={HiOutlineSparkles}
                                                                            boxSize="20px"
                                                                            color="main"
                                                                        />
                                                                    </Flex>

                                                                    <Text
                                                                        fontSize="14px"
                                                                        fontWeight="400"
                                                                        color="font"
                                                                        ml="auto"
                                                                    >
                                                                        <Text as="span" color="font">
                                                                            {leccionesIA.filter(leccion => 
                                                                                checkLessonComplete(cursoProgress?.data, leccion.id)
                                                                            ).length}
                                                                        </Text>
                                                                        {" / "}
                                                                        <Text as="span" color="font">
                                                                            {leccionesIA.length}
                                                                        </Text>
                                                                    </Text>
                                                                    <AccordionIcon
                                                                        transform={isExpanded ? 'rotate(-180deg)' : 'none'}
                                                                        transition="transform 0.2s"
                                                                    />
                                                                </Flex>
                                                            </AccordionButton>

                                                            <AccordionPanel p="10px 0 0 0">
                                                                {leccionesIA?.map((leccion: LeccionInt, index: number) => (
                                                                    <Flex
                                                                        key={index}
                                                                        w="100%"
                                                                        mb="5px"
                                                                        cursor="pointer"
                                                                        p="10px"
                                                                        bg={leccion?.slug === params["*"] 
                                                                            ? colorMode === "dark" ? "main" : "main_variant"
                                                                            : colorMode === "dark" ? "#262C36" : "bg_light"
                                                                        }
                                                                        rounded="5px"
                                                                        _hover={{ bg: colorMode === "dark" ? "main" : "main_variant" }}
                                                                        onClick={() => {
                                                                            navigate(`${leccion?.slug}${addRedirect}`);
                                                                            onClose && onClose();
                                                                            setContenidoOpen && setContenidoOpen(!contenidoOpen);
                                                                        }}
                                                                    >
                                                                        <Flex
                                                                            alignItems="center"
                                                                            justifyContent="space-between"
                                                                            w="100%"
                                                                        >
                                                                            <Text
                                                                                color="font"
                                                                                fontSize="14px"
                                                                            >
                                                                                {leccion?.nombre}
                                                                            </Text>

                                                                            {user?.role?.nombre === UserRolEnum.ALUMNO && 
                                                                                checkLessonComplete(cursoProgress?.data, leccion?.id) && (
                                                                                    <Icon
                                                                                        as={BiCheck}
                                                                                        boxSize="20px"
                                                                                        color={colorMode === "dark" ? "success" : "success_variant"}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Flex>
                                                                    </Flex>
                                                                ))}
                                                            </AccordionPanel>
                                                        </>
                                                    )}
                                                </AccordionItem>
                                            </Accordion>
                                        </Flex>
                                    )}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                );
            })}
        </Accordion>
    )
}

export { AccordionContenido }