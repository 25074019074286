import {
  Box,
  VStack,
  Heading,
  Text,
  HStack,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useAudioRecorder } from '../../../../../../shared/hooks/VoiceRecorder/useAudioRecorder';
import { useWaveform } from '../../../../../../shared/hooks/VoiceRecorder/useWaveform';
import { useAudioVisualizer } from '../../../../../../shared/hooks/VoiceRecorder/useAudioVisualizer';
import { FaPlay, FaMicrophone, FaStop } from 'react-icons/fa';

interface VoiceRecorderProps {
  onRecordingComplete: (audioBlob: Blob) => void;
}

export function VoiceRecorder({ onRecordingComplete }: VoiceRecorderProps) {
  const waveformRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {
    isRecording,
    audioUrl,
    startRecording,
    stopRecording,
    setupRecorder,
    beginRecording,
  } = useAudioRecorder();

  const { loadAudio, playAudio } = useWaveform(waveformRef);
  const { setupAudioContext, drawWaveform, cleanup } = useAudioVisualizer();

  const handleStartRecording = async () => {
    try {
      const stream = await startRecording();
      if (!stream) return;

      if (waveformRef.current) {
        waveformRef.current.style.display = 'none';
      }
      if (canvasRef.current) {
        canvasRef.current.style.display = 'block';
      }

      setupRecorder((audioBlob) => {
        const timestamp = new Date().getTime();
        const audioFile = new File([audioBlob], `audio_${timestamp}.wav`, {
          type: 'audio/wav'
        });

        if (waveformRef.current) {
          waveformRef.current.style.display = 'block';
        }
        if (canvasRef.current) {
          canvasRef.current.style.display = 'none';
        }

        loadAudio(URL.createObjectURL(audioFile));
        onRecordingComplete(audioFile);
        
        cleanup();
      });

      setupAudioContext(stream);
      drawWaveform(canvasRef);
      beginRecording();
    } catch (err) {
      console.error('Error starting recording:', err);
    }
  };

  return (
    <VStack w="full" spacing={6}>
      <Flex direction="column" align="center" gap="10px">
        <Heading as="h2" fontSize="xl" color="indigo.900" mb={2}>
          Grabar tu respuesta
        </Heading>

        <Text fontSize="16px" w="70%" textAlign="center">
          Cuando termines de grabar automáticamente se enviará la respuesta para su corrección, luego podrás reproducirla y si es necesario, puedes volver a grabar.
        </Text>
      </Flex>

      <HStack justify="center" spacing={4}>
        <IconButton
          aria-label={isRecording ? "Detener grabación" : "Iniciar grabación"}
          icon={isRecording ? <FaStop /> : <FaMicrophone />}
          onClick={isRecording ? stopRecording : handleStartRecording}
          colorScheme={isRecording ? "gray" : "red"}
          rounded="full"
          size="lg"
        />

        <IconButton
          isDisabled={!audioUrl}
          aria-label="Reproducir audio"
          icon={<FaPlay />}
          onClick={playAudio}
          colorScheme="blue"
          rounded="full"
          size="lg"
        />
      </HStack>

      <Box
        position="relative"
        h="120px"
        w="70%"
        borderRadius="xl"
        overflow="hidden"
        bg="white"
        boxShadow="lg"
        border="1px"
        borderColor="gray.200"
        p={4}
      >
        <Box ref={waveformRef} position="absolute" inset={0} />

        <Box
          as="canvas"
          ref={canvasRef}
          position="absolute"
          inset={0}
          w="full"
          h="full"
          display="none"
        />

        {isRecording && (
          <Flex
            position="absolute"
            top={3}
            right={3}
            align="center"
            gap={2}
          >
            <Box
              animation="pulse 2s infinite"
              w="2.5"
              h="2.5"
              bg="red.500"
              borderRadius="full"
            />

            <Text fontSize="sm" color="red.500" fontWeight="medium">
              Grabando
            </Text>
          </Flex>
        )}
      </Box>
    </VStack>
  );
}