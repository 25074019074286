import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Text, useColorMode, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import CardExamen from "./CardExamen"
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors"
import CardTutor from "./CardTutor"
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum"
import RequisitosCurso from "./RequisitosCurso"
import { useProgressContext } from "../../../../shared/context/progress.context"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../../../shared/context/user.context"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"
import { useData } from "../../../../shared/hooks/useData"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes"
import InfoCursoTutor from "./InfoCursoTutor"
import InfoCurso from "./InfoCurso"

function ModalinfoCursoMobile() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { slug } = useParams();
    const btnRef = React.useRef<HTMLButtonElement>(null)
    const { cursoProgress, setCursoProgress, refreshProgress } = useProgressContext()
    const navigate = useNavigate()
    const toast = useToast()
    const { user, logout } = useAuthContext();
    const [matriculaActual, setMatriculaActual] = useState<MatriculasInt>()
    const { colorMode } = useColorMode();
    const { data: evaluaciones } = useData({
        endpoint: EndpointTypes.EVALUACIONES
    })

    const { data: matriculas, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });

    const { data: curso, loading, error: errorCurso, status: statusCurso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: slug
    })

    useEffect(() => {
        if (errorCurso) {
            if (statusCurso === 403) {
                navigate("/aprendizaje")

                toastNotify(toast, StatusEnumTypes.ERROR, "No tienes acceso a este curso")
            }
        }
    }, [errorCurso])

    const { data: examen } = useDataId({
        endpoint: EndpointTypes.EXAMENES,
        id: curso?.examen?.slug
    })

    useEffect(() => {
        if (matriculas && slug) {
            const matriculaEncontrada = matriculas?.data?.find((matricula: MatriculasInt) => matricula?.grupo?.curso?.slug === slug);

            if (matriculaEncontrada) {
                setMatriculaActual(matriculaEncontrada);
            }
        }
    }, [matriculas, slug]);

    useEffect(() => {
        if (!matriculaActual || !curso) return;

        setCursoProgress((prev: any) => ({
            ...prev,
            matriculaId: matriculaActual?.id,
            cursoId: curso?.id,
            cursoSlug: slug,
            cursoNombre: curso?.nombre,
            totalLecciones: curso?.meta?.totalLecciones,
        }));

        setTimeout(() => {
            if (matriculaActual?.id && curso?.id) {
                refreshProgress();
            }
        }, 0);

    }, [matriculaActual, curso])

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast,
            })
        }
    }, [error])

    const checkExamenCursoAprobado = () => {
        if (!matriculaActual?.evaluaciones || matriculaActual?.evaluaciones?.length === 0) return false;
        else return matriculaActual?.evaluaciones?.find((evaluacion: any) => evaluacion?.aprobado) ? true : false;
    }

    const evaluacionesFiltradas = evaluaciones?.data?.filter((evaluacion: any) => evaluacion.matriculaId === matriculaActual?.id);

    const isSuspendido = evaluacionesFiltradas?.length >= examen?.numIntentos;

    return (
        <Flex
            display={{ base: 'flex', md: 'none' }}
            justify='center'
        >
            <Button
                ref={btnRef}
                bg="main"
                color="purewhite"
                onClick={onOpen}
                fontSize='16px'
                w='100%'
            >
                Requisitos e información adicional
            </Button>
            <Drawer
                size='full'
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader
                        p="10px"
                    >
                        <Button
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                onClose()
                            }}
                        >
                            Volver
                        </Button>
                    </DrawerHeader>
                    <DrawerBody
                        px="10px"
                    >
                        <Flex
                            direction="column"
                            w='100%'
                            justify='center'
                            gap="30px"
                        >
                            {user?.role?.nombre === UserRolEnum.ALUMNO || user?.role?.nombre === UserRolEnum.ADMIN ?
                                <>

                                    <InfoCurso
                                        matricula={matriculaActual}
                                        curso={curso}
                                    />
                                        {user?.role?.nombre === UserRolEnum.ALUMNO && curso?.tutorizado &&
                                            <CardTutor
                                                tutor={matriculaActual?.tutor}
                                            />
                                        }

                                        {curso?.examen &&
                                            <Flex
                                                direction="column"
                                                gap="16px"
                                            >
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="700"
                                                    color="font"
                                                >
                                                    Examen del curso
                                                </Text>

                                                <CardExamen
                                                    aprobado={checkExamenCursoAprobado()}
                                                    examen={curso?.examen!}
                                                    nombre={curso?.nombre}
                                                    type="curso"
                                                    disabled={(cursoProgress?.porcentajeCurso || 0) < 75 || isSuspendido}
                                                    matriculaId={matriculaActual?.id}
                                                    isSuspendido={isSuspendido}
                                                />
                                            </Flex>
                                        }

                                        {user?.role?.nombre === UserRolEnum.ALUMNO &&
                                            <RequisitosCurso
                                                matriculaActual={matriculaActual}
                                                curso={curso}
                                            />
                                        }
                                </>
                                :
                                <InfoCursoTutor
                                    curso={curso}
                                />
                            }
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}

export default ModalinfoCursoMobile