import { Box, Button, Flex, Icon, ListItem, Text, Textarea, UnorderedList, useColorMode, useToast, Spinner } from "@chakra-ui/react"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { TipoEntregaEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";
import { LeccionInt } from "../../../../../interfaces/CursosInt";
import { PiSparkleBold } from "react-icons/pi";
import { motion, AnimatePresence } from "framer-motion";
import { VoiceRecorder } from "./VoiceRecorder/VoiceRecorder";
import { FaCss3, FaHtml5 } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { CodeiumEditor } from "@codeium/react-code-editor";
import { FaJs } from "react-icons/fa";

export enum LenguajesEnum {
    JavaScript = "javascript",
    Python = "python",
    HTML = "html",
    CSS = "css",
}

interface Props {
    tipo: TipoEntregaEnum;
    leccion: LeccionInt | undefined;
    loading: boolean;
    realizarCorreccion: (respuesta: string | Blob | File) => Promise<void>;
    setNuevoIntento?: Dispatch<SetStateAction<boolean>>;
    currentAnswer?: string | Blob | File;
    setCurrentAnswer?: Dispatch<SetStateAction<string | Blob | File>>;
}

export const ResolverAutocorregible = ({
    tipo,
    leccion,
    loading,
    realizarCorreccion,
    currentAnswer,
    setNuevoIntento = () => { },
    setCurrentAnswer
}: Props) => {
    const { colorMode } = useColorMode();
    const [lenguaje, setLenguaje] = useState<LenguajesEnum>(LenguajesEnum.JavaScript);
    const [respuesta, setRespuesta] = useState<string | Blob>(
        leccion?.AI === "texto" || leccion?.AI === "codigo" ? (leccion?.AI === "texto" ? "" : "console.log('Hello, world!');") : new Blob([], { type: 'audio/wav' })
    );

    return (
        <AnimatePresence mode="wait">
            <Flex
                direction="column"
                maxW={"calc(100vw - 565px)"}
                minH="300px"
                alignItems="center"
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 } as any}
            >
                {loading ? (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            height: "300px",
                        }}
                    >
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            mt="100px"
                            position="relative"
                            mx="auto"
                        >
                            <Box
                                position="absolute"
                                width="300px"
                                height="300px"
                                background="radial-gradient(circle, rgba(122, 103, 255, 0.15) 0%, transparent 50%)"
                                filter="blur(30px)"
                                opacity="0.1"
                            />

                            <Box
                                position="absolute"
                                width="176px"
                                height="195px"
                                borderRadius="86px"
                                opacity="0.4"
                                background="linear-gradient(90deg, #C082FF 0%, #7967FF 100%)"
                                filter="blur(75px)"
                                ml="140px"
                            />

                            <Flex
                                direction="column"
                                align="center"
                                gap="25px"
                                ml="140px"
                            >
                                <Spinner
                                    thickness="3px"
                                    speed="0.8s"
                                    emptyColor="whiteAlpha.100"
                                    color="#7967FF"
                                    size="xl"
                                    width="50px"
                                    height="50px"
                                />
                                <Text
                                    color="white"
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Analizando tu solución... Esto llevará solo unos segundos ✨
                                </Text>
                            </Flex>

                        </Flex>
                    </motion.div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            width: "100%",
                        }}
                    >
                        <Flex
                            direction="column"
                            fontSize="13px"
                            fontWeight="400"
                            color="font"
                            gap="10px"
                            mr="auto"
                        >
                            <Text
                                display={tipo === TipoEntregaEnum.ENUNCIADO ? "flex" : "none"}
                                fontSize="14px"
                                fontWeight="500"
                                color="font"
                            >
                                Tu respuesta:
                            </Text>

                            <Text
                                display={tipo === TipoEntregaEnum.REENTREGA ? "block" : "none"}
                                fontSize="14px"
                                fontWeight="500"
                                color="font"
                            >
                                Nueva respuesta:
                            </Text>

                            <Flex
                                direction="column"
                                maxW="calc(100vw - 565px)"
                                w="full"
                                ml="8%"
                            >
                                {leccion?.AI === "codigo" &&
                                    <Flex
                                        w="105%"
                                        direction="column"
                                    >
                                        <Flex
                                            bg="#222222"
                                            mt="20px"
                                        >
                                            {Object.values(LenguajesEnum).map((lang) => (
                                                <Button
                                                    key={lang}
                                                    onClick={() => setLenguaje(lang)}
                                                    bg={lenguaje === lang ? "#303030" : "transparent"}
                                                    color={lenguaje === lang ? "purewhite" : "#D1D1D1"}
                                                    h="fit-content"
                                                    w="fit-content"
                                                    p="10px 16px"
                                                    rounded="0"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    leftIcon={
                                                        <Icon
                                                            as={lang === LenguajesEnum.JavaScript ? FaJs : lang === LenguajesEnum.Python ? FaPython : lang === LenguajesEnum.HTML ? FaHtml5 : lang === LenguajesEnum.CSS ? FaCss3 : FaJs}
                                                            color={lenguaje === lang ? (lang === LenguajesEnum.JavaScript ? "yellow.500" : lang === LenguajesEnum.Python ? "green.500" : lang === LenguajesEnum.HTML ? "orange.500" : lang === LenguajesEnum.CSS ? "blue.500" : "yellow.500") : "#D1D1D1"}
                                                        />
                                                    }
                                                >
                                                    {lang}
                                                </Button>
                                            ))}
                                        </Flex>

                                        <CodeiumEditor
                                            language={lenguaje}
                                            theme="vs-dark"
                                            onChange={(code: string | undefined) => setRespuesta(code || "")}
                                            value={respuesta instanceof Blob ? "" : respuesta}
                                            containerStyle={{
                                                paddingTop: "20px",
                                                backgroundColor: "#1E1E1E",
                                            }}
                                        />
                                    </Flex>
                                }

                                {leccion?.AI === "texto" &&
                                    <Textarea
                                        value={respuesta instanceof Blob ? "" : respuesta}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setRespuesta(e.target.value)}
                                        minHeight="300px"
                                        resize="none"
                                        mt="20px"
                                    />
                                }

                                {leccion?.AI === "audio" &&
                                    <VoiceRecorder
                                        onRecordingComplete={(audioBlob: Blob) => {
                                            setRespuesta(audioBlob);
                                            if (setCurrentAnswer) setCurrentAnswer(audioBlob);
                                        }}
                                    />
                                }

                                <Flex
                                    w="100%"
                                    justifyContent="center"
                                    mt="20px"
                                    ml="50px"
                                    mx="auto"
                                >
                                    <Box
                                        width="299px"
                                        height="86px"
                                        borderRadius="86px"
                                        opacity="0.2"
                                        background="linear-gradient(90deg, #C082FF 0%, #7967FF 100%)"
                                        filter="blur(35px)"
                                        mr="-280px"
                                    />
                                    <Button
                                        py="15px"
                                        px="24px"
                                        h="fit-content"
                                        w="fit-content"
                                        mt="30px"
                                        bgGradient="linear(90deg, #A12DFF 0%, #7967FF 100%)"
                                        borderRadius="10px"
                                        color="white"
                                        fontSize="16px"
                                        fontWeight="500"
                                        isLoading={loading}
                                        transition="all 0.2s ease"
                                        _hover={{
                                            boxShadow: "0 4px 15px rgba(121, 103, 255, 0.3)",
                                        }}
                                        _disabled={{ bg: "#A5A8B3", cursor: "not-allowed" }}
                                        onClick={async (e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            let respuestaToSend = respuesta;

                                            if (leccion?.AI === "audio" && respuesta instanceof Blob) {
                                                const audioFile = new File([respuesta], 'audio.wav', { type: 'audio/wav' });
                                                respuestaToSend = audioFile;
                                            } else {
                                                respuestaToSend = currentAnswer || respuesta || "";
                                            }

                                            await realizarCorreccion(respuestaToSend);
                                            setNuevoIntento(false);
                                        }}
                                        leftIcon={<Icon as={PiSparkleBold} color="white" />}
                                    >
                                        Corrección Inteligente
                                    </Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </motion.div>
                )}
            </Flex>
        </AnimatePresence>
    )
}