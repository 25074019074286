import { useEffect, useState } from 'react'
import { getGrupo } from '../../../../shared/middlewares/public.middleware'
import NuevasMatriculasTable from '../../components/NuevasMatriculasTable'
import { Flex, Icon, Text, useColorMode } from '@chakra-ui/react'
import { format } from '../../../../shared/utils/functions/formatDate'
import { useParams } from 'react-router-dom'
import { BiMoon, BiSun } from 'react-icons/bi'

export default function NuevasMatriculas() {
    const [grupo, setGrupo] = useState<any>(null)
    const [matriculasEnviadas, setMatriculasEnviadas] = useState(false)
    const { hash, numMatriculas } = useParams();
    const { colorMode, toggleColorMode } = useColorMode()

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (hash) {
                    const response = await getGrupo({ hashString: hash });
                    setGrupo(response.data.data)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [hash, numMatriculas, matriculasEnviadas]);
    
    if (grupo?.prematriculas?.status === 'finished') {
        return (
            <Flex
                direction="column"
                align="center"
                justify="center"
                minHeight="100vh"
                gap={4}
            >
                <Text fontSize="2xl" fontWeight="bold" color="green.500">
                    ¡Éxito!
                </Text>
                <Text fontSize="lg">
                    Las matrículas han sido enviadas correctamente.
                </Text>
            </Flex>
        );
    }

    return (
        <Flex
            direction="column"
            gap="20px"
            p="40px"
            pb="0px"
            alignItems="center"
        >
            <Flex
                alignItems="center"
                gap="20px"
                w="100%"
            >
                <Flex
                    direction="column"
                    alignItems="center"
                    gap="20px"
                    w="100%"
                    mr="-50px"
                >
                    <Text
                        fontSize="21px"
                        fontWeight="700"
                        color="font"
                    >
                        Nuevas matrículas
                    </Text>

                    <Text
                        fontSize="14px"
                        fontWeight="500"
                        color="font"
                        mt="-10px"
                    >
                        Añade los alumnos que quieres matricular y sus datos correspondientes. Debes rellenar todas las filas y sus campos requeridos.
                    </Text>
                </Flex>
                <Flex
                    border="1px solid"
                    borderColor="font"
                    borderRadius="10px"
                    p="7px"
                    shadow="md"
                    onClick={toggleColorMode}
                    cursor="pointer"
                >
                    <Icon
                        as={colorMode === "dark" ? BiSun : BiMoon}
                        color="variant_font"
                        w="20px"
                        h="20px"
                        ml="auto"
                    />

                </Flex>
            </Flex>

            <Flex
                gap="20px"
                direction="column"
                alignItems="center"
                mt="10px"
                border="1px solid #E2E8F0"
                p="20px"
                bg={colorMode === "dark" ? "dark_bg" : "purewhite"}
                rounded="15px"
            >
                <Flex
                    gap="10px"
                >
                    <Text
                    >
                        Grupo: <Text as="span" fontWeight="700">{grupo?.nombre}</Text>
                    </Text>

                    <Text>
                        Fecha Inicio:{" "}
                        <Text
                            as="span"
                            fontWeight="700"
                        >
                            {grupo?.fechaInicio ? format({ date: grupo?.fechaInicio, customFormat: "dd/MM/yyyy" }) : "No existe"}
                        </Text>
                    </Text>

                    <Text>
                        Fecha Fin:{" "}
                        <Text
                            as="span"
                            fontWeight="700"
                        >
                            {grupo?.fechaFin ? format({ date: grupo?.fechaFin, customFormat: "dd/MM/yyyy" }) : "No existe"}
                        </Text>
                    </Text>
                </Flex>

            </Flex>


            <NuevasMatriculasTable
                grupoPrematricula={grupo}
                rows={Number(numMatriculas)}
                onMatriculasEnviadas={() => setMatriculasEnviadas(true)}
            />

            <Flex
                direction="column"
                alignItems="center"
                maxW="900px"
                gap="10px"
                mt="40px"
            >
                <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="font"
                >
                    IMPORTANTE:
                </Text>

                {!grupo?.fundae ?
                    <Text
                        textAlign="center"
                        fontSize="12px"
                    >
                        No nos haremos responsables de la veracidad de los datos facilitados, quedando exentos de cualquier responsabilidad en caso de aplicación indebida de bonificaciones y/o Requerimiento/Inspección de las Acciones Formativas. En caso de que existan alumnos de varias Sociedades, se debe indiciar la empresa en la que está contratado el trabajador.
                    </Text>
                    :
                    <>
                        <Text
                            textAlign="center"
                            fontSize="12px"
                        >
                            No nos haremos responsables de la veracidad de los datos facilitados, quedando exentos de cualquier responsabilidad en caso de aplicación indebida de bonificaciones y/o Requerimiento/Inspección de la Accion Formativa. Los autónomos, becarios, funcionaros, no pueden bonificarse. En caso de que existan alumnos de varias Sociedades, se debe indiciar la empresa en la que está contratado el trabajador.
                        </Text>

                        <Text
                            textAlign="center"
                            fontSize="12px"
                        >
                            Con el fin de evitar cualquier incidencia o requerimiento asociado a esta formación, recomendamos mantenernos autorizados como entidad organizadora.
                        </Text>

                        <Text
                            textAlign="center"
                            fontSize="12px"
                        >
                            Se debe cumplir el dato de confinanciación privada exigido, para evitar cualquier indicencia o requerimiento asociado.
                        </Text>
                    </>
                }
            </Flex>

            <Text
                bottom="0"
                width="800px"
                textAlign="center"
                fontSize="8px"
                p="30px"
                pb="10px"
                color="font_grey"
            >
                AVISO LEGAL Y POLÍTICA DE PRIVACIDAD

                De conformidad con lo dispuesto en el Reglamento General de Protección de Datos de la UE (RGPD) y en la Ley Orgánica de Protección de Datos 3/2018 de 5 de diciembre, te informamos que has recibido este correo porque nos has autorizado expresamente. Los datos personales que aparecen en esta comunicación son tratados por IMAGINA WEB 6 MOBILE TECHNOLOGIES SL con CIF B98330335 con la finalidad de prestar servicios de formación y consultoría. Los datos no se cederán a terceros salvo obligación legal.
            </Text>
        </Flex>
    )
}
