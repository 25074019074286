import { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useTenantInfo } from '../useTenantInfo';

export function useWaveform(containerRef: React.RefObject<HTMLDivElement>) {
  const { handleGetInfo } = useTenantInfo();
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const colores = handleGetInfo({ key: "colores" });

  useEffect(() => {
    if (containerRef.current) {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }

      wavesurferRef.current = WaveSurfer.create({
        container: containerRef.current,
        waveColor: colores?.secondary || '#4F46E5',
        progressColor: colores?.main || '#2563EB',
        cursorWidth: 0,
        height: 120,
        normalize: true,
        hideScrollbar: true,
        barWidth: 2,
        barGap: 1,
        barRadius: 3,
        minPxPerSec: 50,
        fillParent: true,
        interact: false,
        autoCenter: true,
        sampleRate: 44100,
      });

      wavesurferRef.current.on('error', (err) => {
        console.error('WaveSurfer error:', err);
      });
    }

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [containerRef.current]);

  const loadAudio = async (url: string) => {
    try {
      if (wavesurferRef.current) {
        await wavesurferRef.current.load(url);
      }
    } catch (err) {
      console.error('Error loading audio:', err);
    }
  };

  const playAudio = () => {
    try {
      if (wavesurferRef.current) {
        wavesurferRef.current.play();
      }
    } catch (err) {
      console.error('Error playing audio:', err);
    }
  };

  return { loadAudio, playAudio };
}