import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, CircularProgress, CircularProgressLabel, Flex, Icon, Text, useColorMode } from '@chakra-ui/react'
import { sortByOrder } from '../../../../shared/utils/functions/sortBy';
import { CursosInt, LeccionInt, ModulosInt } from '../../../../interfaces/CursosInt';
import { checkLessonComplete, checkLessonStart, validateProgressModule } from '../../../../shared/utils/functions/validateProgress';
import { useProgressContext } from '../../../../shared/context/progress.context';
import { BiCheck, BiListUl } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isRoleAllowed } from '../../../../shared/utils/functions/validateRol';
import { UserRolEnum } from '../../../../shared/utils/types/UserRolEnum';
import { LeccionTipoEnum } from '../../../../shared/utils/types/LeccionTipoEnum';
import { HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineLink, HiOutlinePaperClip, HiOutlinePlay, HiOutlineSparkles } from 'react-icons/hi';
import { useAuthContext } from '../../../../shared/context/user.context';
import { BsPerson } from 'react-icons/bs';
import CardExamen from './CardExamen';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { EstadoTypeEnum } from '../../../../shared/utils/types/EstadoTypeEnum';
import { useState } from 'react';
import { AutocorregibleNavigation } from './AutocorregibleNavigation';

interface Props {
    curso?: CursosInt;
    matricula: MatriculasInt | undefined;
}

export default function AccordionContenido({ curso, matricula }: Props) {
    const { user } = useAuthContext();
    const { cursoProgress } = useProgressContext();
    const navigate = useNavigate();
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""
    const { colorMode } = useColorMode();
    const [currentQuestions, setCurrentQuestions] = useState<{ [key: string]: number }>({});

    const handleNextQuestion = (moduloId: string, totalQuestions: number) => {
        setCurrentQuestions(prev => ({
            ...prev,
            [moduloId]: Math.min((prev[moduloId] || 1) + 1, totalQuestions)
        }));
    };

    const handlePrevQuestion = (moduloId: string) => {
        setCurrentQuestions(prev => ({
            ...prev,
            [moduloId]: Math.max((prev[moduloId] || 1) - 1, 1)
        }));
    };

    const getCurrentQuestion = (moduloId: string) => {
        return currentQuestions[moduloId] || 1;
    };

    return (
        <Accordion
            allowMultiple
            gap="20px"
        >
            {sortByOrder(curso?.modulos)?.map((modulo: ModulosInt, index: number) => {
                const leccionesTexto = sortByOrder(modulo?.lecciones)?.filter(
                    (leccion: LeccionInt) => leccion?.AI === "texto" || leccion?.AI === "audio" || leccion?.AI === "codigo"
                );
                const leccionesNoTexto = sortByOrder(modulo?.lecciones)?.filter(
                    (leccion: LeccionInt) => leccion?.AI !== "texto" && leccion?.AI !== "audio" && leccion?.AI !== "codigo"
                );

                return (
                    <AccordionItem
                        key={index}
                        my="10px"
                        border="none"
                    >
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton
                                    border="1px solid"
                                    borderColor={colorMode === "dark" ? "#576776" : "border"}
                                    rounded={isExpanded ? "20px 20px 0 0" : "20px"}
                                    borderBottom={isExpanded ? "none" : "1px solid"}
                                    borderBottomColor={isExpanded ? "transparent" : colorMode === "dark" ? "#576776" : "border"}
                                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                    p="15px"
                                    w="full"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    gap="20px"
                                >
                                    <Flex>
                                        {user?.role?.nombre === UserRolEnum?.ALUMNO ?
                                            <CircularProgress
                                                capIsRound
                                                value={validateProgressModule(cursoProgress, modulo)}
                                                color="main"
                                                trackColor={colorMode === "dark" ? "#424242" : "#EAECFF"}
                                                size="55px"
                                            >
                                                <CircularProgressLabel
                                                    fontSize="20px"
                                                    fontWeight="600"
                                                    lineHeight="90%"
                                                >
                                                    {sortByOrder(curso?.modulos)[0]?.nombre?.includes("Recurso") || sortByOrder(curso?.modulos)[0]?.nombre?.includes("recurso") ? index : index + 1}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                            :
                                            <Flex
                                                boxSize="55px"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    fontSize="20px"
                                                    fontWeight="600"
                                                    lineHeight="90%"
                                                >
                                                    {sortByOrder(curso?.modulos)[0]?.nombre?.includes("Recurso") || sortByOrder(curso?.modulos)[0]?.nombre?.includes("recurso") ? index : index + 1}
                                                </Text>
                                            </Flex>
                                        }
                                    </Flex>

                                    <Flex
                                        alignItems="start"
                                        gap="5px"
                                        w="100%"
                                        direction="column"
                                    >
                                        <Text
                                            fontSize="16px"
                                            fontWeight="600"
                                            color="font"
                                            align="start"

                                        >
                                            {modulo?.nombre}
                                        </Text>

                                        <Flex
                                            align="center"
                                            gap="5px"
                                        >
                                            <Icon
                                                as={BiListUl}
                                                boxSize="20px"
                                                color="font"
                                            />

                                            <Text
                                                fontSize="13px"
                                                fontWeight="400"
                                                color="font_grey"
                                            >
                                                {modulo?.lecciones?.length} lecciones
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >

                                        <Flex
                                            gap="30px"
                                            boxSize="20px"
                                        >
                                            {user?.role?.nombre === UserRolEnum?.ALUMNO &&
                                                (validateProgressModule(cursoProgress, modulo) > 99 && (
                                                    <Icon
                                                        color={colorMode === "dark" ? "success" : "success_variant"}
                                                        boxSize="20px"
                                                        rounded="full"
                                                        as={BiCheck}
                                                    />
                                                ))}
                                        </Flex>

                                        <Flex
                                            boxSize="32px"
                                            rounded="full"
                                            bg="main"
                                            justify="center"
                                            align="center"
                                        >
                                            <AccordionIcon
                                                color="purewhite"
                                            />
                                        </Flex>

                                    </Flex>

                                </AccordionButton>

                                <AccordionPanel
                                    border="1px solid"
                                    borderColor={colorMode === "dark" ? "#576776" : "border"}
                                    rounded="0 0 20px 20px"
                                    borderTop="none"
                                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                    p="15px"
                                >
                                    <Flex
                                        flexDirection="column"
                                        w="100%"
                                        gap="10px"
                                    >
                                        {leccionesNoTexto?.map((leccion: LeccionInt, index: number) => (
                                            <Flex
                                                key={index}
                                                justifyContent="space-between"
                                                p="10px 20px"
                                                cursor={matricula?.estado === EstadoTypeEnum.ACTIVO ? "pointer" : user?.role?.nombre === UserRolEnum.TUTOR ? "pointer" : "default"}
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()
                                                    if (user?.role?.nombre === UserRolEnum?.ALUMNO) {
                                                        if (matricula?.estado === EstadoTypeEnum.ACTIVO) return navigate(`lecciones/${leccion?.slug}${addRedirect}`)
                                                        else return null
                                                    } else return navigate(`lecciones/${leccion?.slug}`)
                                                }}
                                                bg={colorMode === "dark" ? "#262C36" : "bg_light"}
                                                rounded="14px"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Icon
                                                        color={user?.role?.nombre === UserRolEnum?.ALUMNO ?
                                                            (
                                                                checkLessonComplete(cursoProgress?.data, leccion?.id)
                                                                    ? colorMode === "dark" ? "main" : "secondary"
                                                                    : "font"
                                                            )
                                                            : "font"
                                                        }
                                                        as={
                                                            leccion?.tipo === LeccionTipoEnum.FICHERO
                                                                ? HiOutlinePaperClip
                                                                : leccion?.tipo === LeccionTipoEnum.ENTREGABLE
                                                                    ? HiOutlineClipboard
                                                                    : leccion?.tipo === LeccionTipoEnum.URL
                                                                        ? HiOutlineLink
                                                                        : leccion?.tipo === LeccionTipoEnum.TEXTO
                                                                            ? HiOutlineDocumentText
                                                                            : leccion?.tipo === LeccionTipoEnum.SESION_VIVO
                                                                                ? (leccion?.sesionVivo?.online ? HiOutlinePlay : BsPerson)
                                                                                : HiOutlineDocumentDownload
                                                        }
                                                        boxSize="20px"
                                                    />
                                                    <Text>{leccion?.nombre}</Text>
                                                </Flex>

                                                {user?.role?.nombre === UserRolEnum?.ALUMNO && (
                                                    checkLessonComplete(cursoProgress?.data, leccion?.id) &&
                                                    <Icon
                                                        as={BiCheck}
                                                        boxSize="20px"
                                                        color={colorMode === "dark" ? "success" : "success_variant"}
                                                    />
                                                )}
                                            </Flex>
                                        ))}

                                        {leccionesTexto?.length > 0 && (
                                            <Flex
                                                direction="column"
                                                px="20px"
                                                py="10px"
                                                bg={colorMode === "dark" ? "#1E2329" : "white"}
                                                rounded="14px"
                                                border="1px solid"
                                                borderColor={colorMode === "dark" ? "main" : "border"}
                                            >
                                                <Accordion allowToggle width="100%">
                                                    <AccordionItem border="none">
                                                        {({ isExpanded }) => (
                                                            <>
                                                                <AccordionButton
                                                                    p="0"
                                                                    _hover={{ bg: 'transparent' }}
                                                                    _focus={{ boxShadow: 'none' }}
                                                                >
                                                                    <Flex
                                                                        alignItems="center"
                                                                        gap="8px"
                                                                        justifyContent="space-between"
                                                                        width="100%"
                                                                    >
                                                                        <Flex align="center" gap="8px">
                                                                            <Icon
                                                                                as={HiOutlineSparkles}
                                                                                boxSize="20px"
                                                                                color="main"
                                                                            />
                                                                            <Text
                                                                                fontSize="18px"
                                                                                fontWeight="500"
                                                                                color="font"
                                                                            >
                                                                                Autocorregibles
                                                                            </Text>
                                                                        </Flex>

                                                                        <AccordionIcon
                                                                            transform={isExpanded ? 'rotate(-180deg)' : 'none'}
                                                                            transition="transform 0.2s"
                                                                        />
                                                                    </Flex>
                                                                </AccordionButton>

                                                                <AccordionPanel p="10px 0 0 0">
                                                                    <Flex direction="column" gap="10px">
                                                                        {leccionesTexto?.map((leccion: LeccionInt, index: number) => (
                                                                            <Flex
                                                                                key={index}
                                                                                w="full"
                                                                                p="10px"
                                                                                bg={colorMode === "dark" ? "#262C36" : "bg_light"}
                                                                                rounded="14px"
                                                                                cursor={matricula?.estado === EstadoTypeEnum.ACTIVO ? "pointer" : user?.role?.nombre === UserRolEnum.TUTOR ? "pointer" : "default"}
                                                                                onClick={(e: React.MouseEvent) => {
                                                                                    e.stopPropagation()
                                                                                    if (user?.role?.nombre === UserRolEnum?.ALUMNO) {
                                                                                        if (matricula?.estado === EstadoTypeEnum.ACTIVO) 
                                                                                            return navigate(`lecciones/${leccion?.slug}${addRedirect}`)
                                                                                        else return null
                                                                                    } else return navigate(`lecciones/${leccion?.slug}`)
                                                                                }}
                                                                                justifyContent="space-between"
                                                                                alignItems="center"
                                                                            >
                                                                                <Text>{leccion?.nombre}</Text>
                                                                                {user?.role?.nombre === UserRolEnum?.ALUMNO && 
                                                                                    checkLessonComplete(cursoProgress?.data, leccion?.id) && (
                                                                                        <Icon
                                                                                            as={BiCheck}
                                                                                            boxSize="20px"
                                                                                            color={colorMode === "dark" ? "success" : "success_variant"}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Flex>
                                                                        ))}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </>
                                                        )}
                                                    </AccordionItem>
                                                </Accordion>
                                            </Flex>
                                        )}

                                        {modulo?.examen && (user?.role?.nombre === UserRolEnum?.ALUMNO || user?.role?.nombre === UserRolEnum?.ADMIN) &&
                                            <CardExamen
                                                examen={modulo?.examen!}
                                                type={"modulo"}
                                                nombre={modulo?.nombre}
                                                disabled={validateProgressModule(cursoProgress, modulo) < 99}
                                                matriculaId={matricula?.id}
                                                withoutRedirection={matricula?.estado !== EstadoTypeEnum.ACTIVO}
                                            />
                                        }
                                    </Flex>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                )
            })}
        </Accordion>
    )
}
