import React from 'react';
import { Field } from 'formik';
import { FormControl, Input, InputGroup, useColorMode } from '@chakra-ui/react';

export const FormInputVerify = React.forwardRef(({
    name,
    placeholder,
    onBlur = () => { },
    onFocus = () => { },
    onChange,
    onPaste,
}: {
    name: string;
    placeholder?: string;
    onBlur?: (e?: any) => any;
    onFocus?: (e?: any) => any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}, ref) => {
    const { colorMode } = useColorMode();

    return (
        <Field name={name}>
            {({ field }: { field: any; form: any }) => (
                <FormControl style={{ width: "42px" }}>
                    <InputGroup pos="relative">
                        <Input
                            {...field}
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]"
                            maxLength={1}
                            onKeyDown={(e) => {
                                if (!/[0-9]/.test(e.key) &&
                                    e.key !== 'Backspace' &&
                                    e.key !== 'Tab' &&
                                    e.key !== 'Delete' &&
                                    !e.ctrlKey &&
                                    !e.metaKey) {
                                    e.preventDefault();
                                }
                            }}
                            borderRadius="5px"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            borderColor="grey"
                            placeholder={placeholder}
                            value={field.value || ''}
                            _placeholder={{
                                color: "dark_grey",
                                fontSize: "13px",
                                fontWeight: "500",
                                lineHeight: "16px",
                                letterSpacing: "-0.078px",
                            }}
                            ref={ref}
                            onChange={onChange}
                            onPaste={onPaste}
                            fontSize="14px"
                            fontWeight="700"
                            height="42px"
                            w="42px"
                            textAlign="center"
                            border="1px solid #A6A3A3"
                            rounded="10px"
                            bg="transparent"
                            color="purewhite"
                        />
                    </InputGroup>
                </FormControl>
            )}
        </Field>
    );
});
