import { Link as LinkRouter } from 'react-router-dom'
import { Link, Icon, Text, Flex, Box, useColorMode } from "@chakra-ui/react";
import { useTenantInfo } from '../../../hooks/useTenantInfo';

interface Props {
    path: string;
    icon: any;
    label: string;
    active?: boolean;
    fontSize?: string
}

export const NavLink = ({ path, icon, label, active = false, fontSize = '14px' }: Props) => {
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const { colorMode } = useColorMode()
    return (
        <Link
            as={LinkRouter}
            to={path}
            _hover={{ textDecoration: "none" }}
            w="100%"
            minW="185px"
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                pr="0px"
                gap="6px"
                direction="row"
                alignItems="center"
                transition="all 0.1s"
                _hover={{ color: "main", bg: colorMode === "dark" ? "bg_dark_light" : "main_variant" }}
                bg={active ? (colorMode === "dark" ? "#212224" : "purewhite") : "transparent"}
                color={active ? "main" : colorMode === "dark" ? "purewhite" : "font"}
                rounded="8px"
            >
                <Box
                    w="5px"
                    h="40px"
                    bg="main"
                    roundedTopRight="5px"
                    roundedBottomRight="5px"
                    opacity={active ? 1 : 0}
                />

                <Flex
                    gap="12px"
                    py="10px"
                    pl={{ base: "5px", sm: "10px" }}
                    pr="10px"
                    align='center'
                >
                    <Icon
                        as={icon}
                        boxSize="20px"
                        color={!active ? "font" : "main"}
                    />

                    <Text
                        fontSize={fontSize}
                        fontWeight={active ? "600" : "500"}
                        color={!active ? "font" : colorMode === "dark" ? "purewhite" : "main"}
                        alignItems="center"
                    >
                        {label}
                    </Text>
                </Flex>
            </Flex>
        </Link>
    );
};