import { Navigate, Route, Routes } from "react-router-dom";
import { VerifyEmail } from "./views/VerifyEmail/VerifyEmail";
import { Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import { ValidateCode } from "./views/ValidateCode/ValidateCode";
import { RegisterComplete } from "./views/Register/RegisterComplete";
import homeBg from "../../assets/img/home-bg.png";
import homeDashboard from "../../assets/img/home-dashboard.png";

export default function RegisterRoutes() {
    const { colorMode } = useColorMode()

    return (
        <Flex
            w="100%"
            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            h="100vh"
            overflow={"hidden"}
        >
            <Flex
                flex="1"
            >
                <Routes>
                    <Route
                        index
                        element={<Navigate to="/register/verifyEmail" />}
                    />

                    <Route
                        path="/verifyEmail"
                        element={<VerifyEmail />}
                    />

                    <Route
                        path="/validateCode"
                        element={<ValidateCode />}
                    />

                    <Route
                        path="/:hash"
                        element={<RegisterComplete />}
                    />
                </Routes>
            </Flex>

            <Flex
                flex="1"
                bgImage={homeBg}
                bgSize="cover"
                bgRepeat="no-repeat"
            >
                <Image
                    src={homeDashboard}
                    alt='home'
                    objectFit={"contain"}
                    ml={"80px"}
                />
            </Flex>
        </Flex>
    )
}
