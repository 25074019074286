import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    useToast,
    Tooltip,
    useColorMode,
} from '@chakra-ui/react'

import { useState } from 'react';
import { updateHorarioLaboral } from '../../middlewares/users.middleware';
import { DiaTypeEnum } from '../../utils/types/TipoJornadaLaboralEnum';

import { JornadaLaboral } from '../../utils/types/TipoJornadaLaboralEnum';
import { useAuthContext } from '../../context/user.context';
import { toastNotify } from '../../utils/functions/toastNotify';
import { StatusEnumTypes } from '../../utils/types/StatusEnumTypes';
import { HorarioLaboralSelect } from '../Customs/HorarioLaboral/HorarioLaboralSelect';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const UserDisponibilidadData: JornadaLaboral = {
    [DiaTypeEnum.LUNES]: [],
    [DiaTypeEnum.MARTES]: [],
    [DiaTypeEnum.MIERCOLES]: [],
    [DiaTypeEnum.JUEVES]: [],
    [DiaTypeEnum.VIERNES]: [],
    [DiaTypeEnum.SABADO]: [],
    [DiaTypeEnum.DOMINGO]: []
};

export const ModalFundae = ({ isOpen, onClose }: Props) => {
    const { refreshUser } = useAuthContext();
    const toast = useToast();
    const [jornadaLaboral, setJornadaLaboral] = useState<JornadaLaboral | undefined>(UserDisponibilidadData)
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const { colorMode } = useColorMode()

    const validateCompletData = (jornadaLaboral: JornadaLaboral | undefined) => {
        if (!jornadaLaboral) return false

        const dias: DiaTypeEnum[] = [
            DiaTypeEnum.LUNES,
            DiaTypeEnum.MARTES,
            DiaTypeEnum.MIERCOLES,
            DiaTypeEnum.JUEVES,
            DiaTypeEnum.VIERNES,
            DiaTypeEnum.SABADO,
            DiaTypeEnum.DOMINGO
        ]

        for (let i = 0; i < dias?.length; i++) {
            if (jornadaLaboral[dias[i]]?.length > 0) return true
        }
    }

    const handleSubmit = () => {
        if (jornadaLaboral === undefined) return toastNotify(toast, StatusEnumTypes.INFO, "Debe completar todos los campos")
        else if (!validateCompletData(jornadaLaboral)) return toastNotify(toast, StatusEnumTypes.INFO, "Debe completar su jornada laboral")

        updateHorarioLaboral({
            horarioLaboral: jornadaLaboral
        })

            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Su horario se guardo correctamente")
                refreshUser({
                    horarioLaboral: jornadaLaboral
                })
            })
            .finally(() => onClose())
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="3xl"
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                p="30px"
            >

                <ModalBody>
                    <HorarioLaboralSelect
                        label="Complete su jornada laboral"
                        sublabel="Hola! Te damos la bienvenida a este curso realizado a través de FUNDAE, necesitamos saber tu horario laboral antes de comenzar, no dudes en contactar con nosotros para cualquier consulta, gracias."
                        onChangeSelect={setJornadaLaboral}
                        defaultValueSelect={jornadaLaboral}
                        setIsChecked={setIsChecked}
                        isChecked={isChecked}
                    />
                </ModalBody>

                <ModalFooter>
                    <Tooltip
                        label={"Debe confirmar que el horario indicado sea correcto."}
                        isDisabled={isChecked}
                    >
                        <Button
                            p="10px 15px"
                            h="fit-content"
                            w="fit-content"
                            bg="main"
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="500"
                            onClick={handleSubmit}
                            isDisabled={!isChecked}
                        >
                            Confirmar
                        </Button>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}