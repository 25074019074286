import { Box, Flex, Button, Text, Image, Link, useColorMode, Icon, useToast } from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { iconoTenant } from '../../../../shared/middlewares/tenant.middleware';
import { useTenantInfo } from '../../../../shared/hooks/useTenantInfo';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import tenantInstance from '../../../../shared/services/tenantInstance.service';
import { FormInputVerify } from '../../../../shared/components/FormElements/FormInputVerify';
import { HiOutlineMail } from 'react-icons/hi';
import { validateCode } from '../../../../shared/middlewares/register.middleware';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/types/StatusEnumTypes';

export const ValidateCode = () => {
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const navigate = useNavigate()
    const toast = useToast()
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingWidth, setLoadingWidth] = useState("0%")

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!loading) return;

        let width = 0;
        const interval = setInterval(() => {
            width += 1;
            setLoadingWidth(`${width}%`);

            if (width >= 90) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [loading]);

    useEffect(() => {
        if (loading) return;

        setLoadingWidth("100%");

        setTimeout(() => {
            setLoadingWidth("0%");
        }, 500);
    }, [loading]);

    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
    const inputs: string[] = ["caracterOne", "caracterTwo", "caracterThree", "caracterFour", "caracterFive", "caracterSix"];
    const [loadingValidateCode, setLoadingValidateCode] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const initialValues = {
        caracterOne: null,
        caracterTwo: null,
        caracterThree: null,
        caracterFour: null,
        caracterFive: null,
        caracterSix: null,
    };

    const validationSchema = Yup.object().shape({
        caracterOne: Yup.string()
            .notRequired(),
        caracterTwo: Yup.string()
            .notRequired(),
        caracterThree: Yup.string()
            .notRequired(),
        caracterFour: Yup.string()
            .notRequired(),
        caracterFive: Yup.string()
            .notRequired(),
        caracterSix: Yup.string()
            .notRequired(),
    });

    const checkInputs = (values: any) => {
        const { caracterOne, caracterTwo, caracterThree, caracterFour, caracterFive, caracterSix } = values;

        if (!caracterOne || !caracterTwo || !caracterThree || !caracterFour || !caracterFive || !caracterSix) {
            setError(true);
            return false;
        }

        return true;
    }

    const submitForm = async (values: any) => {
        if (!checkInputs(values)) return;
        setLoadingValidateCode(true)

        const code = `${values.caracterOne}${values.caracterTwo}${values.caracterThree}${values.caracterFour}${values.caracterFive}${values.caracterSix}`;
        const email = localStorage.getItem("verifyEmail");

        if (!email) {
            toastNotify(toast, StatusEnumTypes.ERROR, "Error al obtener la información vuelva a intentarlo.")
            return;
        }

        validateCode({ codigo: code, email })
            .then((response) => {
                const hash = response?.data?.data

                if (!hash) {
                    toastNotify(toast, StatusEnumTypes.ERROR, "Error al obtener la información vuelva a intentarlo.")
                    return;
                }

                localStorage.removeItem("verifyEmail")
                navigate(`/register/${hash}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoadingValidateCode(false))
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        if (value.length === 1 && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handlePaste = (
        event: React.ClipboardEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    ) => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === 6) {
            const values = pastedData.split('');
            values.forEach((value, i) => {
                setFieldValue(inputs[i], value);
            });
        }
    };

    return (
        <Flex
            w={"100%"}
            bg="black"
            align="center"
            justify="center"
        >
            <Box
                pos={"fixed"}
                top="0"
                bg="#8694FF"
                h="3px"
                w={loadingWidth}
            />

            <Flex
                direction={"column"}
                align="start"
                justify="center"
                w="320px"
            >
                <Flex
                    border="1px solid"
                    borderColor={colores?.main ? colores?.main : 'main'}
                    padding="10px"
                    rounded="10px"
                >
                    <Icon
                        as={HiOutlineMail}
                        boxSize="24px"
                        color={colores?.main ? colores?.main : 'main'}
                    />
                </Flex>

                <Flex
                    direction="column"
                    align='start'
                    justify='center'
                    textAlign='center'
                    gap="4px"
                    mt="30px"
                    mb="45px"
                >
                    <Text
                        fontFamily="Poppins"
                        fontWeight="700"
                        fontSize="22px"
                        color="purewhite"
                    >
                        Verifiquemos tu email
                    </Text>

                    <Text
                        mt="20px"
                        fontWeight="400"
                        lineHeight="normal"
                        fontSize="14px"
                        color="#AEAEAE"
                        textAlign="left"
                    >
                        Revisa tu email para verificar tu cuenta y comencemos.
                    </Text>
                </Flex>

                {!loading &&
                    <Formik
                        onSubmit={(values) => submitForm(values)}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {(formik) => {
                            const { handleSubmit, setFieldValue, handleChange } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                    style={{ minWidth: "320px" }}
                                >
                                    <Flex
                                        direction="column"
                                        alignItems="start"
                                        gap="5px"
                                    >
                                        <Flex
                                            w="100%"
                                            gap="10px"
                                        >
                                            {inputs.map((item: string, index: number) => (
                                                <FormInputVerify
                                                    key={index}
                                                    name={item}
                                                    ref={(el: any) => (inputRefs.current[index - 1] = el)}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(event);
                                                        handleInputChange(event, index - 1);
                                                    }}
                                                    onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => handlePaste(event, setFieldValue)}
                                                />
                                            ))}
                                        </Flex>

                                        {error &&
                                            <Text
                                                mt="10px"
                                                fontWeight="400"
                                                lineHeight="normal"
                                                fontSize="15px"
                                                color="red.500"
                                            >
                                                Debe introducir el codigo completo.
                                            </Text>
                                        }

                                        <Button
                                            type='submit'
                                            background={colores?.main ? colores?.main : 'main'}
                                            color="#FFFFFF"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="20px"
                                            rounded="10px"
                                            w="100%"
                                            h="fit-content"
                                            py="11px"
                                            px="25px"
                                            mt="40px"
                                            isLoading={loadingValidateCode}
                                        >
                                            Continuar
                                        </Button>

                                        <Flex
                                            direction="column"
                                            gap="30px"
                                            mt="30px"
                                        >
                                            <Text
                                                color="rgba(255, 255, 255, 0.50)"
                                                fontSize="13px"
                                                fontWeight="400"
                                                lineHeight="20px"
                                            >
                                                ¿Necesitas ayuda? {" "}
                                                <Text
                                                    as="span"
                                                    color={colores?.main ? colores?.main : 'main'}
                                                >
                                                    Contacte con soporte
                                                </Text>
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                }
            </Flex>
        </Flex>
    )
}