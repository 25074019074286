import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useToast, Flex, useColorMode } from "@chakra-ui/react";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../../shared/context/user.context";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { createChat, createMessage } from "../../../../shared/middlewares/messages.middleware";
import { CarpetaMensajeria, EstadoMensaje } from "../../../../shared/utils/types/MensajeriaEnum";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { FormTextEditor } from "../../../../shared/components/FormElements/FormTextEditor";
import { CustomSelect } from "../../../../shared/components/Customs/CustomSelect";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { EstadoTypeEnum } from "../../../../shared/utils/types/EstadoTypeEnum";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { formSelectStyles, formSelectStylesDark } from "../../../../ui/Select/formSelectStyles";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    Refresh: () => void;
}

export const NewChatModal = ({ isOpen, onClose, Refresh }: Props) => {
    const { user } = useAuthContext();
    const toast = useToast();
    const navigate = useNavigate();
    const { colorMode } = useColorMode()
    const { data } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        query: {
            limit: 100
        }
    });

    const matriculas = { data: data?.data?.filter((matricula: MatriculasInt) => matricula?.meta?.tutorizado) }

    const [optGrupos, setOptGrupos] = useState<{
        value: string | undefined;
        label: string;
        matriculaId: string | undefined;
        tutorId: string | undefined;
        cursoId: string | undefined;
    }[]>([])
    const [grupo, setGrupo] = useState<{
        value: string | undefined;
        label: string;
        matriculaId: string | undefined;
        tutorId: string | undefined;
        cursoId: string | undefined;
    }>()
    const [optLecciones, setOptLecciones] = useState<{ value: string, label: string }[]>([])

    const { data: lecciones } = useData({
        endpoint: EndpointTypes.LECCIONES,
        query: {
            curso: grupo?.cursoId ? grupo?.cursoId : "",
            limit: 100,
            sortBy: 'createdAt',
            order: 'asc'
        },
    })

    useEffect(() => {
        if (!grupo?.cursoId || !lecciones?.data) return

        const newOptions: { value: string, label: string }[] = lecciones.data.map((leccion: any) => ({
            value: leccion.id,
            label: leccion.nombre
        }))

        setOptLecciones(newOptions)
    }, [grupo, lecciones])

    useEffect(() => {
        if (optGrupos && optGrupos?.length !== 0 || !matriculas?.data) return
        const newOptions: {
            value: string | undefined;
            label: string;
            matriculaId: string | undefined;
            tutorId: string | undefined;
            cursoId: string | undefined;
        }[] = []

        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (!matricula || (matricula?.estado === EstadoTypeEnum.INACTIVO && user?.role?.nombre === UserRolEnum.ALUMNO)) return

            return newOptions.push({
                value: matricula?.grupoId,
                label: matricula?.grupo?.curso?.nombre + " - " + matricula?.grupo?.nombre,
                matriculaId: matricula?.id,
                tutorId: matricula?.tutorId,
                cursoId: matricula?.grupo?.cursoId
            })
        })

        newOptions.length > 0 ? setOptGrupos(newOptions) : null;

    }, [matriculas])

    const validationSchema = Yup.object().shape({
        leccionId: Yup.string()
            .required("Debe seleccionar una lección"),
        asunto: Yup.string()
            .required("Debe establecer un asunto"),
        mensaje: Yup.string()
            .required("Debe escribir un mensaje")
    });

    const initialValues = {
        leccionId: null,
        asunto: null,
        mensaje: undefined
    };

    const submitForm = async (values: any) => {
        if (!user?.id) return;

        const newChat = {
            matriculaId: grupo?.matriculaId,
            receptorId: grupo?.tutorId,
            leccionId: values.leccionId,
            emisorId: user?.id,
            asunto: values.asunto,
            carpeta: CarpetaMensajeria.ENTRADA
        }

        toastNotify(toast, StatusEnumTypes.SUCCESS, "¡Tu duda ha sido enviada correctamente!");

        createChat(newChat)
            .then((response: AxiosResponse) => {
                user?.id &&
                    createMessage({
                        conversacionId: response?.data?.data?.id,
                        emisorId: user?.id,
                        receptorId: grupo?.tutorId,
                        texto: values.mensaje,
                        estado: EstadoMensaje.NO_LEIDO
                    })
                        .then(() => {

                            navigate(`/mensajeria/${response?.data?.data?.id}`);
                            Refresh();
                        })
                        .finally(() => {
                            handleClose();
                        })
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al iniciar conversacion, vuelva a intentarlo si el error persiste contacte con soporte"))
    };

    function onKeyDown(keyEvent: any) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) keyEvent.stopPropagation();
    }

    const handleClose = () => {
        setGrupo(undefined)
        setOptLecciones([])
        setOptGrupos([])
        onClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="4xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                px={{ base: "10px", sm: "24px" }}
                rounded={{ base: "0px", sm: "20px" }}
                height={{ base: "100vh", sm: "auto" }}
            >
                <Button
                    w="fit-content"
                    mt="10px"
                    py="8px"
                    display={{ base: "flex", sm: "none" }}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        handleClose()
                    }}
                >
                    Volver
                </Button>
                <ModalHeader
                    color="main"
                    fontSize="24px"
                    fontWeight="600"
                    lineHeight="140%"
                    textAlign="start"
                    p={{ base: "10px 0px", sm: "25px 0px" }}
                >
                    Nueva Duda
                </ModalHeader>

                <Formik
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm();
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit, values } = formik;

                        return (
                            <FormikForm onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                <ModalBody
                                    px="0px"
                                    py="0px"
                                >
                                    <Flex
                                        direction="column"
                                        gap={{ base: "5px", sm: "15px" }}
                                    >
                                        <FormSelect
                                            name='grupo'
                                            label="Curso"
                                            placeholder="Elige el curso sobre el que vas a hablar"
                                            options={optGrupos}
                                            onChangeCallback={(e: { value: string; label: string; matriculaId: string; tutorId: string; cursoId: string; }) => setGrupo(e)}
                                            ui={{
                                                formSelectStyles: colorMode === "dark" ? formSelectStylesDark : formSelectStyles,
                                                styleLabel: {
                                                    fontSize: "14px",
                                                    color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                }
                                            }}
                                        />

                                        <Flex
                                            cursor={!grupo?.cursoId ? "not-allowed" : "default"}
                                            mb="-15px"
                                        >
                                            <FormSelect
                                                name='leccionId'
                                                isDisabled={!grupo?.cursoId}
                                                label="Lección"
                                                placeholder="Elige la lección de la que trate tu duda"
                                                options={optLecciones}
                                                ui={{
                                                    formSelectStyles: colorMode === "dark" ? formSelectStylesDark : formSelectStyles,
                                                    styleLabel: {
                                                        fontSize: "14px",
                                                        color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                    }
                                                }}
                                            />
                                        </Flex>

                                        <FormInput
                                            name="asunto"
                                            label="Asunto"
                                            placeholder="¿De qué trata tu mensaje?"
                                            ui={{
                                                styleInput: {
                                                    borderRadius: '10px',
                                                    borderColor: colorMode === "dark" ? "border_variant" : "#E2E8F0",
                                                    backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                    color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                },
                                                styleLabel: {
                                                    fontSize: "14px",
                                                    color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                }
                                            }}
                                        />

                                        <Flex
                                            mt="-15px"
                                        >
                                            <FormTextEditor
                                                name="mensaje"
                                                label="Mensaje"
                                                placeholder="Escriba aqui..."
                                                height="150px"
                                                onChange={formik.setFieldValue}
                                                styles={{
                                                    borderRadius: "10px",
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </ModalBody>

                                <ModalFooter
                                    gap="20px"
                                    justifyContent="end"
                                    mb="10px"
                                    px="0px"
                                    py={{base: "0px", sm: "16px"}}
                                >
                                    <Button
                                        display={{ base: "none", sm: "flex" }}
                                        onClick={handleClose}
                                        p="12px"
                                        h="fit-content"
                                        w="fit-content"
                                        bg="transparent"
                                        color="main"
                                        fontSize="16px"
                                        fontWeight="500"
                                        border="1px solid"
                                        borderColor="main"
                                        _hover={{ bg: "variant" }}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        p="12px"
                                        h="fit-content"
                                        w="fit-content"
                                        bg="main"
                                        color="purewhite"
                                        fontSize="16px"
                                        fontWeight="500"
                                        type="submit"
                                        _hover={{ bg: "variant" }}
                                    >
                                        Enviar duda
                                    </Button>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal >
    );
};