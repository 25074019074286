import { Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import MarkdownPreview from "@uiw/react-markdown-preview"
import { truncate } from 'lodash';
import { useNavigate } from 'react-router-dom';

interface Props {
    topLine?: boolean;
    dashedLine?: boolean;
    curso?: any;
}

export default function RutaCurso({ dashedLine, curso }: Props) {
    const navigate = useNavigate();
    const { colorMode } = useColorMode()

    const finalizado = curso?.meta?.lecciones_count === curso?.meta?.lecciones_completadas;

    return (
        <Flex
            gap="20px"
            onClick={() => navigate(`/aprendizaje/${curso?.slug}`)}
            w="100%"
        >
            <Flex
                direction="column"
                alignItems="center"
                gap="0"
            >
                <Flex
                    position="relative"
                    bg={finalizado ? "linear(to-b, #70C1A4, #04906C)" : "#212224"}
                    w="62px"
                    h="62px"
                    rounded="34.506px"
                    p="10px"
                    _before={{
                        content: '""',
                        position: 'absolute',
                        top: '-1px',
                        right: '-1px',
                        bottom: '-1px',
                        left: '-1px',
                        zIndex: -1,
                        rounded: '34.506px',
                        bgGradient: 'linear(to-b, #70C1A4, #04906C)'
                    }}
                    boxShadow="0px -4px 13px 0px rgba(25, 153, 91, 0.30), 0px 4px 13px 0px rgba(25, 153, 91, 0.30)"
                >
                    <Image
                        src={curso?.icono?.url}
                        w="152px"
                        objectFit="cover"
                        roundedRight="20px"
                    />
                </Flex>

                <Flex
                    w="1px"
                    h="full"
                    my="10px"
                    borderLeft="1px"
                    borderStyle={dashedLine ? "dashed" : "solid"}
                    borderColor={finalizado ? "#70C1A4" : "#353637"}
                />

            </Flex>

            <Flex
                w="100%"
                h="202px"
                rounded="20px"
                bg={colorMode === "dark"
                    ? "linear-gradient(115deg, #242424 -20.36%, #000 142.37%)"
                    : "purewhite"}
                position="relative"
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: '-1px',
                    right: '-1px',
                    bottom: '-1px',
                    left: '-1px',
                    zIndex: -1,
                    rounded: '20px',
                    bgGradient: colorMode === "dark"
                        ? 'linear(to-br, #646464, #2A2A2A)'
                        : 'none',
                    opacity: colorMode === "dark" ? 1 : 0,
                }}
                border={colorMode === "dark" ? "none" : "1px solid"}
                borderColor={colorMode === "dark" ? "transparent" : "#E6E6EA"}
                cursor="pointer"
                transition="all 0.2s"
                _hover={{
                    boxShadow: "0px 0px 10px 0px rgba(255, 255, 255, 0.20)"
                }}
            >
                <Flex
                    p="24px"
                    gap="16px"
                    w="100%"
                    justifyContent="space-between"
                >
                    <Flex
                        gap="12px"
                        direction="column"
                    >
                        <Flex
                            direction="column"
                        >
                            <Text
                                fontSize="24px"
                                fontWeight="bold"
                                color={colorMode === "dark" ? "#FFF" : "#121625"}
                            >
                                {curso?.nombre}
                            </Text>

                            <Text
                                fontSize="16px"
                                fontWeight="600"
                                bgGradient="linear(90deg, #70C1A4 0%, #04906C 100%)"
                                bgClip="text"
                            >
                                {curso?.meta?.lecciones_count} ejercicios prácticos
                            </Text>
                        </Flex>

                        <MarkdownPreview
                            source={curso?.descripcion}
                            style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: colorMode === "dark" ? "#B5B9C5" : "#121625",
                                backgroundColor: "transparent"
                            }}
                            wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                        />
                    </Flex>
                    <Flex
                        bg="#05906C"
                        border="1px solid"
                        borderColor="#70C1A4"
                        color="purewhite"
                        rounded="5px"
                        w="fit-content"
                        p="5px 8px"
                        h="fit-content"
                        fontSize="14px"
                        fontWeight="600"
                        boxShadow="2px 2px 4px 0px rgba(255, 255, 255, 0.15)"
                        whiteSpace="nowrap"
                    >
                        {curso?.meta?.modulos_count} módulos | {curso?.meta?.pivot_duracion}h
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
