import { Flex, useToast } from "@chakra-ui/react"
import { PreviewLeccion } from "../Previews/Preview"
import { FooterLeccion } from "../../components/FooterLeccion"
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum"
import { useAuthContext } from "../../../../shared/context/user.context"
import { useLeccionActions } from "../../../../shared/hooks/useLeccionActions"
import { CursosInt, LeccionInt } from "../../../../interfaces/CursosInt"
import { useNavigate, useParams } from "react-router-dom"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"
import { useEffect, useState } from "react"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { EstadoTypeEnum } from "../../../../shared/utils/types/EstadoTypeEnum"
import { getUsersInactividad } from "../../../../shared/middlewares/users.middleware"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes"

interface Props {
    curso: CursosInt;
    matriculaActual: MatriculasInt | undefined;
}

export const LeccionesSlug = ({
    curso,
    matriculaActual
}: Props) => {
    const { slug } = useParams();
    const { user, authToken } = useAuthContext();
    const navigate = useNavigate()
    const toast = useToast()
    const [leccionSelected, setLeccionSelected] = useState<LeccionInt | undefined>(undefined)

    const { data: leccion, loading, error: errorLeccion, status: statusLeccion } = useDataId({
        endpoint: EndpointTypes.LECCIONES,
        id: slug,
        track: user?.role?.nombre === UserRolEnum.ALUMNO ?
            {
                track: user?.tracking ? true : false,
                matricula: matriculaActual?.estado === EstadoTypeEnum.ACTIVO ? matriculaActual?.id : undefined,
            } : undefined
    })

    useEffect(() => {
        if (errorLeccion) {
            if (statusLeccion !== 200) {
                navigate("/aprendizaje")

                toastNotify(toast, StatusEnumTypes.ERROR, "No tienes acceso a esta lección")
            }
        }
    }, [errorLeccion])

    const {
        setEstadoEntregable,
        onLeccionStarted,
        onPrevLeccion,
        onNextLeccion,
        onEndCurso,
        prevIsBlocked,
        nextIsBlocked,
        mode,
        endPrev,
        alertExamen
    } = useLeccionActions({
        curso: curso,
        leccion: leccion,
        matriculaActual: matriculaActual
    })

    useEffect(() => {
        if (user?.auth && authToken) {
            const intervar = setInterval(() => {
                getUsersInactividad()

            }, 10000);

            return () => clearInterval(intervar)
        }
    }, [user?.auth, authToken])

    return (
        <Flex
            w="100%"
            direction="column"
            py="20px"
            px={{base: "10px", md: "40px"}}
            gap="20px"
            maxH={{base: "calc(100vh - 150px)", sm: "calc(100vh - 75px)"}}
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                'scrollbarWidth': 'none',
                '-ms-overflow-style': 'none',
                scrollBehavior: 'smooth',
            }}
            overflow="auto"
        >
            <PreviewLeccion
                leccion={leccion}
                matriculaActual={matriculaActual}
                setEstadoEntregable={setEstadoEntregable}
                onLeccionStarted={onLeccionStarted}
                onNextLeccion={() => onNextLeccion(curso)}
                endPrev={endPrev}
                loading={loading}
                setLeccionSelected={setLeccionSelected}
                leccionSelected={leccionSelected}
                modulos={curso?.modulos || []}
            />

            {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <FooterLeccion
                    onPrevLeccion={onPrevLeccion}
                    onNextLeccion={onNextLeccion}
                    onEndCurso={onEndCurso}
                    prevIsBlocked={prevIsBlocked}
                    nextIsBlocked={nextIsBlocked}
                    mode={mode}
                    dataCurso={curso}
                    endPrev={endPrev}
                    loading={loading}
                    leccion={leccion}
                    alertExamen={alertExamen}
                />
            }
        </Flex>
    )
}