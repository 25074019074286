import { useTenantInfo } from "../../shared/hooks/useTenantInfo";

export const SetColors = () => {
  const { handleGetInfo } = useTenantInfo();
  const colores = handleGetInfo({ key: "colores" });

  return {
    main: (!colores || !colores?.main) ? '#A12DFF' : colores?.main,
    secondary: (!colores || !colores?.secondary) ? '#0F1122' : colores?.secondary,
    variant: (!colores || !colores?.variant) ? '#6B6EFF' : colores?.variant,
    main_variant: '#EAECFF',
    bg_color: '#F2F2FD',
    bg_light: "#F8F8F9",
    bg_dark: "#0F1011",
    bg_dark_variant: "#110F16",
    bg_dark_light: "#191D23",
    border: '#E2E8F0',
    border_variant: '#576776',
    gradient: '--Linear-2, linear-gradient(135deg, #21D4FD 0%, #4FD1C5 100%)',
    dark_blue: '#00152D',
    grey: '#F1F3F6',
    light_grey: '#EAEAEA',
    dark_grey: '#959596',
    pureblack: '#000',
    white: '#F8F8F8',
    purewhite: '#FFFFFF',
    disable: '#A0AEC0',
    success: "#76E874",
    success_variant: "#14804A",
    success_bg: "#E1FCEF",
    fail: "#F24343",
    fail_bg: "#FFDBE3",
    blue: "#4FD1C5",
    blue_bg: "#C1FFF9",
    font: {
      _light: "#121625",
      _dark: "#F8F8F8"
    },
    font_grey: {
      _light: "#A5A8B3",
      _dark: "#959596"
    },
    // warning: "#FFF7B4",
    // pending: "#EFC569",
    warning: "#E09133",
    warning_bg: "#FAEFE0",
    warning_variant: "#867800",
    pending: "#EFC569",
    secondary_variant_font: '#959596',
    main_shadow: '0px 4px 15px 0px rgba(199, 205, 255, 0.60)',


    "chakra-body-bg": {
      _light: "#F8F8F9",
      _dark: "#0F1011",
    },
  }
}
