import { Button, Flex, Text, useColorMode } from '@chakra-ui/react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { motion } from 'framer-motion';

interface Props {
    currentQuestion: number;
    totalQuestions: number;
    onNextQuestion: () => void;
    onPrevQuestion: () => void;
    fullWidth?: boolean;
    hasNext?: boolean;
    hasPrev?: boolean;
}

export const AutocorregibleNavigation = ({
    currentQuestion,
    totalQuestions,
    onNextQuestion,
    onPrevQuestion,
    fullWidth = false
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Flex
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            w={fullWidth ? "" : "20%"}
            alignItems="center"
            gap="5px"
        >
            <Button
                onClick={onPrevQuestion}
                // isDisabled={currentQuestion === 1}
                variant="ghost"
                color={colorMode === "dark" ? "white" : "font"}
                _hover={{
                    bg: colorMode === "dark" ? "whiteAlpha.200" : "blackAlpha.100"
                }}
                bg={colorMode === "dark" ? "#262C36" : "bg_light"}
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "border"}
            >
                <BiChevronLeft size={20} />
            </Button>

            <Button
                onClick={onNextQuestion}
                // isDisabled={currentQuestion === totalQuestions}
                variant="ghost"
                color={colorMode === "dark" ? "white" : "font"}
                _hover={{
                    bg: colorMode === "dark" ? "whiteAlpha.200" : "blackAlpha.100"
                }}
                bg={colorMode === "dark" ? "#262C36" : "bg_light"}
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "border"}
            >
                <BiChevronRight size={20} />
            </Button>
        </Flex>
    );
}; 