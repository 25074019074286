import { Flex, Icon, Image, Text, useColorMode, useDisclosure, useBreakpointValue, Button, Tooltip } from '@chakra-ui/react'
import { BsClock } from 'react-icons/bs';
import avatarDefault from "../../../assets/img/avatarDefault.png"
import { format } from '../../../shared/utils/functions/formatDate';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../shared/context/user.context';
import { ConversationChatMobile } from './ConversationChatMobile';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { updateMessage } from '../../../shared/middlewares/messages.middleware';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes';
import { EstadoMensaje } from '../../../shared/utils/types/MensajeriaEnum';
import { useDataId } from '../../../shared/hooks/useDataId';
import { EndpointTypes } from '../../../shared/utils/types/EndpointTypes';
import { MensajesInt } from '../../../interfaces/MessagesInt';

interface Props {
    conversacion: any;
    refresh: () => void;
}

export default function ChatSelectorItem({ conversacion, refresh }: Props) {
    const { user } = useAuthContext()
    const { colorMode } = useColorMode()
    const params = useParams()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const openDrawerChat = useBreakpointValue({ base: true, md: false });

    const openChat = () => {
        if (!params["*"] || params["*"] !== conversacion?.id || (openDrawerChat && params["*"] === conversacion?.id)) {
            navigate(`/mensajeria/${conversacion?.id}`);
            if (openDrawerChat) {
                onOpen();
            }
        }
    }

    const { data: conversacionData, Refresh: refreshMensaje } = useDataId({
        endpoint: EndpointTypes.MENSAJERIA_CONVERSACION,
        id: conversacion?.id!,
    });

    const handleClick = async () => {
        const primerMensajeNoLeido = conversacionData?.mensajes?.find(
            (mensaje: MensajesInt) => mensaje.estado === EstadoMensaje.NO_LEIDO
        );

        if (!primerMensajeNoLeido?.id) return;

        const updateEstado = {
            id: primerMensajeNoLeido.id,
            estado: EstadoMensaje.LEIDO
        }

        updateMessage(updateEstado)
            .then(() => {
                refresh()
            })
            .catch(() => toastNotify(toastNotify, "Error al marcar como leído", StatusEnumTypes.ERROR))
            .finally(() => (''))
    }

    return (
        <Flex
            p="24px"
            align="start"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            gap="12px"
            cursor="pointer"
            onClick={openChat}
            bg={params["*"] && params["*"] === conversacion?.id ? colorMode === "dark" ? "rgba(234, 236, 255, 0.1)" : "#EAECFF" : ""}
            borderLeft="2px solid"
            borderLeftColor={params["*"] && params["*"] === conversacion?.id ? "main" : "transparent"}
            transition="all 0.3s"
            _hover={{
                background: colorMode === "dark" ? "rgba(234, 236, 255, 0.1)" : "#EAECFF"
            }}
            h="130px"
            w="full"
        >

            <Flex
                w="32px"
                minW="32px"
                h="32px"
            >
                <Image
                    objectFit="cover"
                    src={
                        user?.id === conversacion?.receptorId
                            ? conversacion?.emisor?.avatar?.url || avatarDefault
                            : user?.id === conversacion?.emisorId
                                ? conversacion?.receptor?.avatar?.url || avatarDefault
                                : avatarDefault
                    }
                    rounded="5px"
                />
            </Flex>

            <Flex
                direction="column"
                gap="5px"
                w="full"
            >
                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font"
                >
                    {
                        user?.id === conversacion?.receptorId
                            ? conversacion?.emisor?.nombre + " " + conversacion?.emisor?.apellidos
                            : user?.id === conversacion?.emisorId
                                ? conversacion?.receptor?.nombre + " " + conversacion?.receptor?.apellidos
                                : null
                    }
                </Text>

                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font_grey"
                    w='full'
                    noOfLines={1}
                >
                    {conversacion?.asunto}
                </Text>

                <Flex
                    gap="10px"
                    w="full"
                    justifyContent="space-between"
                >
                    <Flex
                        gap="8px"
                        align="center"
                        mt="12px"
                    >
                        <Icon
                            as={BsClock}
                            boxSize="14px"
                            color="font_grey"
                        />
                        <Text
                            fontSize="11px"
                            fontWeight="400"
                            color="font_grey"
                            whiteSpace="nowrap"
                        >
                            {
                                format({
                                    date: conversacion?.updatedAt,
                                    customFormat: "dd MMM yyyy"
                                })
                            }
                        </Text>
                    </Flex>


                    {
                        conversacion?.meta?.mensajes_count > 0 &&
                        <Tooltip
                            label='Marcar como leído'
                            hasArrow
                        >
                            <Flex
                                fontSize="12px"
                                px="5px"
                                py="5px"
                                fontWeight="400"
                                onClick={handleClick}
                                bg="main"
                                rounded="full"
                                shadow="md"
                                _hover={{
                                    transform: "scale(1.06)",
                                }}
                            >
                                <HiOutlineCheckCircle
                                    size="20px"
                                    color="white"
                                />
                            </Flex>
                        </Tooltip>
                    }

                </Flex>

            </Flex>

            <ConversationChatMobile
                isOpen={isOpen}
                onClose={onClose}
                id={conversacion?.id}
            />
        </Flex>
    )
}
